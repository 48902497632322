import React from 'react'

import styles from './cities-page.module.css'

const CitiesPage = () => {
  return (
    <main className={styles.box}>
      <div className={styles.title}>Города</div>
    </main>
  )
}

export default CitiesPage