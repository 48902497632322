// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ckidrBuECckt5qaPhigy {\n  box-sizing: border-box;\n  padding: 5px;\n  position: absolute;\n  top: 0;\n  right: 30px;\n  width: 200px;\n  background: white;\n  border-radius: 12px;\n  border: 1px solid #eee;\n  overflow: hidden;\n}\n.QUAQt30MDQxSjhH8VBlm {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 10px 5px;\n}\n.QUAQt30MDQxSjhH8VBlm:hover {\n  background: #f6f6f6;\n}\n.QUAQt30MDQxSjhH8VBlm:active {\n  background: #eaeaea;\n}", "",{"version":3,"sources":["webpack://./src/pages/users/users-page/user-block/action-menu/action-menu.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".menu {\n  box-sizing: border-box;\n  padding: 5px;\n  position: absolute;\n  top: 0;\n  right: 30px;\n  width: 200px;\n  background: white;\n  border-radius: 12px;\n  border: 1px solid #eee;\n  overflow: hidden;\n}\n.item {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 10px 5px;\n}\n.item:hover {\n  background: #f6f6f6;\n}\n.item:active {\n  background: #eaeaea;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "ckidrBuECckt5qaPhigy",
	"item": "QUAQt30MDQxSjhH8VBlm"
};
export default ___CSS_LOADER_EXPORT___;
