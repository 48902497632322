import React, { Dispatch, SetStateAction, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import styles from './search.module.css'

interface SearchProps {
  placeholder: string
  handler?: Dispatch<SetStateAction<string>>
}

export const Search = (props: SearchProps) => {

  const onChangeHandler = (e: React.BaseSyntheticEvent) => {
    if (props.handler) {
      props.handler(e.target.value)
    }
  }

  return (
    <div className={styles.search}>
      <span>
        <FiSearch />
      </span>
      <input
        type="text"
        onChange={onChangeHandler}
        placeholder={props.placeholder}
      />
    </div>
  )
}
