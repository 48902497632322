// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wef5IOb6jWP7T47ol3h4 {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.HwssxSIh30dhrcsijuHp {\n  width: 100%;\n}\n.QTTu0XW4cFlrMSmVGwdJ {\n  width: 40%;\n}\n.fkIu8xCZHQ3epddasvs0{\n  width: 20%;\n}\n.R5z0ENFOWyXOyiHigfY7 {\n  width: 20%;\n}\n.ymEGnJEqXbkeUZUjZ82Q {\n  width: 30%;\n}\n\n.M4WHo8pl_EgFqPTMH_L4 {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/shops/shops-page/shops-box/shops-box.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".loader__box {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.box {\n  width: 100%;\n}\n.name {\n  width: 40%;\n}\n.posts{\n  width: 20%;\n}\n.likes {\n  width: 20%;\n}\n.date {\n  width: 30%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader__box": "wef5IOb6jWP7T47ol3h4",
	"box": "HwssxSIh30dhrcsijuHp",
	"name": "QTTu0XW4cFlrMSmVGwdJ",
	"posts": "fkIu8xCZHQ3epddasvs0",
	"likes": "R5z0ENFOWyXOyiHigfY7",
	"date": "ymEGnJEqXbkeUZUjZ82Q",
	"title": "M4WHo8pl_EgFqPTMH_L4"
};
export default ___CSS_LOADER_EXPORT___;
