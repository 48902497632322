import axios from "axios";
import { BASE_URL } from "../constants/constants";

const instanse = axios.create({
  baseURL: `${BASE_URL}/post`
})

export const postApi = {
  delete(id: string | number) {
    const token = localStorage.getItem('accessToken')
    return instanse.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}