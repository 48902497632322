import React from 'react'
import { useShopQuery } from '../../../../services/hooks/shopQuery'
import ShopBlock from './shop-block/shop-block'
import { Loader } from '../../../../components/ui'

import styles from './shops-box.module.css'

const ShopsBox = () => {
  const { isLoading, isSuccess, data } = useShopQuery()

  if (isLoading)
    return (
      <div className={styles.loader__box}>
        <Loader />
      </div>
    )

  if (isSuccess && data)
    return data.length > 0 ? (
      <div className={styles.box}>
        <div className={styles.title}>
          <div className={styles.name}>Название</div>
          <div className={styles.posts}>Посты</div>
          <div className={styles.date}>Дата создания</div>
        </div>
        {data.map((shop) => (
          <ShopBlock shop={shop} key={shop.id} />
        ))}
      </div>
    ) : (
      <div className={styles.loader__box}>Магазины не найдены</div>
    )

  return <div>Ошибка</div>
}

export default ShopsBox