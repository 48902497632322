// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d2w2KemgCVaCh91CQ2qZ {\n  width: 100%;\n}\n\n.wavMyzdBxjf2SLa_ZKiu {\n  width: 30%;\n}\n.kISAoRKuW4Ii6G2mxtAL {\n  width: 20%;\n}\n.CsVe4sDcrNvFjz_GuyU3 {\n  width: 15%;\n}\n.j6KLrtwLDTIaYo7MxNnJ {\n  width: 15%;\n}\n.IRwFoih2nWKZwlWeBtxA {\n  width: 20%;\n}\n\n._JxvCy91bHUwoexD77nX {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/sections-detail-page/categories-table/categories-table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".box {\n  width: 100%;\n}\n\n.name {\n  width: 30%;\n}\n.subcategories {\n  width: 20%;\n}\n.posts {\n  width: 15%;\n}\n.likes {\n  width: 15%;\n}\n.date {\n  width: 20%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "d2w2KemgCVaCh91CQ2qZ",
	"name": "wavMyzdBxjf2SLa_ZKiu",
	"subcategories": "kISAoRKuW4Ii6G2mxtAL",
	"posts": "CsVe4sDcrNvFjz_GuyU3",
	"likes": "j6KLrtwLDTIaYo7MxNnJ",
	"date": "IRwFoih2nWKZwlWeBtxA",
	"title": "_JxvCy91bHUwoexD77nX"
};
export default ___CSS_LOADER_EXPORT___;
