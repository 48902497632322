import React, { useRef, useState } from 'react'
import { imgApi } from '../../../../services/api/img.api'
import { useQueryClient } from '@tanstack/react-query'

import { Loader } from '../../../../components/ui'
import { AiOutlineUpload } from 'react-icons/ai'

import styles from './img-box.module.css'
import { IShop } from '../../../../services/types/shop'

interface ImgBoxProps {
  src: string,
  alt: string,
  id: string,
}

const ImgBox = (props: ImgBoxProps) => {
  const [loading, setLoading] = useState(false)
  const hiddenInputRef = useRef<HTMLInputElement>(null)
  const updateHandler = () => {
    hiddenInputRef.current?.click()
  }
  const imgHandler = async (e: React.BaseSyntheticEvent) => {
    
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', e.target.files[0])
    setLoading(true)
    imgApi.uploadShopImg(formData, props.id)
      .then(res => {
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className={styles.box} onClick={updateHandler}>
      <input
        onChange={imgHandler}
        ref={hiddenInputRef}
        name="image"
        type="file"
        className={styles.hidden}
        accept={'.png,.jpg,.jpeg,.webp'}
      />
      {loading ? (
        <>
          <div className={styles.loader}>
            <Loader color="white" />
          </div>
          <img
            className={styles.img}
            src={`https://api.skidki.promo/img/shops/${props.src}`}
            height={'180px'}
            alt={props.alt}
          />
        </>
      ) : (
        <>
          <div className={styles.btn}>
            <div className={styles.icon}>
              <AiOutlineUpload color="white" size={42} />
            </div>
          </div>
          <img
            className={styles.img}
            src={`https://api.skidki.promo/img/shops/${props.src}`}
            height={'180px'}
            alt={props.alt}
          />
        </>
      )}
    </div>
  )
}

export default ImgBox