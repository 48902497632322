// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mGlqamh_fAJMlE8GJhgF {\n  box-sizing: border-box;\n  height: 40px;\n  width: 40px;\n  min-height: 40px;\n  min-width: 40px;\n  border-radius: 50%;\n  overflow: hidden;\n  border: 1px solid #f1f1f1;\n  object-fit: contain;\n}\n\n.w8cN_OF8lFpHeY3oKwgi {\n  box-sizing: border-box;\n  height: 40px;\n  width: 40px;\n  min-height: 40px;\n  min-width: 40px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 14px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/user-avatar/user-avatar.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".avatar {\n  box-sizing: border-box;\n  height: 40px;\n  width: 40px;\n  min-height: 40px;\n  min-width: 40px;\n  border-radius: 50%;\n  overflow: hidden;\n  border: 1px solid #f1f1f1;\n  object-fit: contain;\n}\n\n.avatar__box {\n  box-sizing: border-box;\n  height: 40px;\n  width: 40px;\n  min-height: 40px;\n  min-width: 40px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 14px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "mGlqamh_fAJMlE8GJhgF",
	"avatar__box": "w8cN_OF8lFpHeY3oKwgi"
};
export default ___CSS_LOADER_EXPORT___;
