import React from 'react'
import UsersPage from './users-page/users-page'

const Users = () => {
  document.title = 'Админка - Пользователи'
  return (
    <UsersPage />
  )
}

export default Users