import React, { useState } from 'react'
import { siteUser } from '../../../../services/types/site-user'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { getTime } from '../../../../services/getTime'

import ActionMenu from './action-menu/action-menu'
import UserAvatar from '../../../../components/user-avatar/user-avatar'

import styles from './user-block.module.css'

interface UserBlockProps {
  user: siteUser
}

const UserBlock = (props: UserBlockProps) => {
  const [ isMenuVisible, setIsMenuVisible ] = useState(false)
  

  return (
    <div className={styles.user}>
      <div className={styles.username}>
        <UserAvatar src={props.user.avatar} username={props.user.username} />
        <div className={styles.info}>
          <p>{props.user.username}</p>
          <p className={styles.email}>{props.user.email}</p>
        </div>
      </div>
      <div className={styles.role}>
        {props.user.role === 'user' ? 'Пользователь' : 'Админ'}
      </div>
      <div className={styles.posts}>{props.user.postsCount}</div>
      <div className={styles.comments}>{props.user.commentsCount}</div>
      <div className={styles.reg}>
        {getTime(new Date(props.user.createdAt!))}
      </div>
      <div className={styles.visit}>
        {props.user.visitedAt ? getTime(new Date(props.user.visitedAt)) : 'Нет'}
      </div>
      <div className={styles.status}>
        {props.user.isBanned ? (
          <div className={styles.banned}>Забанен</div>
        ) : (
          <div className={styles.active}>Активен</div>
        )}
      </div>
      <div className={styles.action__box}>
        <div className={styles.more} onClick={() => setIsMenuVisible(true)}>
          <AiOutlineEllipsis size={20} />
        </div>
        {isMenuVisible && (
          <ActionMenu
            handler={setIsMenuVisible}
            banned={props.user.isBanned!}
            id={props.user.id!}
          />
        )}
      </div>
    </div>
  )
}

export default UserBlock