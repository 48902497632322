import React from 'react'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'

import { Button } from '../../ui'

import styles from './post-detail-page.module.css'

const PostDetailPage = () => {
  const { id } = useParams()

  return (
    <main className={styles.box}>
      <div className={styles.title}>
        <Link to={'/posts'} className={styles.title__name}>
          Посты
        </Link>
        <span> / </span>
        <p>ID: {id}</p>
      </div>
      <div className={styles.menu}>
        <div className={styles.up}>
          <NavLink
            to={`/posts/${id}/info`}
            className={(nav) =>
              nav.isActive
                ? `${styles.item__active} ${styles.item}`
                : styles.item
            }
          >
            Общая информация
          </NavLink>
          <NavLink
            to={`/posts/${id}/comments`}
            className={(nav) =>
              nav.isActive
                ? `${styles.item__active} ${styles.item}`
                : styles.item
            }
          >
            Комментарии
          </NavLink>
        </div>
        <div className={styles.down}>
          <Button name="Отключить комментарии" type="secondary" />
          <Button name="Редактировать" type="secondary" />
          <Button name="Удалить" type="secondary" />
        </div>
      </div>
      <Outlet context={id} />
    </main>
  )
}

export default PostDetailPage