import ReactDOM from "react-dom/client"
import React from "react"
import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import store from "./services/redux"

import App from './components/app/app'
import './index.css'

const client = new QueryClient()
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
)