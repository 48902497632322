import React from 'react'
import { ICategory } from '../../../../services/types/category.type'
import CategoriesItem from './categories-item/categories-item'

import styles from './categories-table.module.css'

interface CategoriesTableProps {
  categories: ICategory[],
}

const CategoriesTable = (props: CategoriesTableProps) => {
  return (
    <div className={styles.box}>
      <div className={styles.title}>
        <div className={styles.name}>Имя</div>
        <div className={styles.subcategories}>Подкатегории</div>
        <div className={styles.posts}>Посты</div>
        <div className={styles.date}>Дата создания</div>
      </div>
      {props.categories
        .map((cat) => (
          <CategoriesItem key={cat.id} category={cat} />
        ))}
    </div>
  )
}

export default CategoriesTable