import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
  loading: boolean,
  authStatErr: boolean,
  adminStatErr: boolean,
  imgStatErr: boolean,
  graphqlStatErr: boolean,
  authStatResTime: string,
  imgStatResTime: string,
  adminStatResTime: string
  graphqlStatResTime: string
}

const initialState: IInitialState = {
  loading: false,
  authStatErr: false,
  adminStatErr: false,
  imgStatErr: false,
  graphqlStatErr: false,
  authStatResTime: '0',
  imgStatResTime: '0',
  adminStatResTime: '0',
  graphqlStatResTime: '0'
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    startStatsRequest(state: IInitialState) {
      state.loading = true
      state.authStatErr = false
      state.imgStatErr = false
      state.adminStatErr = false
      state.graphqlStatErr = false
    },
    endStatsRequest(state: IInitialState) {
      state.loading = false
    },
    authStatsErr(state: IInitialState) {
      state.authStatErr = true
    },
    imgStatsErr(state: IInitialState) {
      state.imgStatErr = true
    },
    adminStatsErr(state: IInitialState) {
      state.adminStatErr = true
    },
    graphqlStatErr(state: IInitialState) {
      state.graphqlStatErr = true
    },
    setAuthResTime(state: IInitialState, action: PayloadAction<string>) {
      state.authStatResTime = action.payload
      state.authStatErr = false
    },
    setImgResTime(state: IInitialState, action: PayloadAction<string>) {
      state.imgStatResTime = action.payload
      state.imgStatErr = false
    },
    setAdminResTime(state: IInitialState, action: PayloadAction<string>) {
      state.adminStatResTime = action.payload
      state.adminStatErr = false
    },
    setGraphqlResTime(state: IInitialState, action: PayloadAction<string>) {
      state.graphqlStatResTime = action.payload
      state.graphqlStatErr = false
    },
  }
})

export const {
  startStatsRequest,
  endStatsRequest,
  authStatsErr,
  imgStatsErr,
  graphqlStatErr,
  setAuthResTime,
  setImgResTime,
  adminStatsErr,
  setAdminResTime,
  setGraphqlResTime
} = statsSlice.actions
export default statsSlice.reducer