// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bid5wY7fSgONRJTfVzJn {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.aCzRVnUu4YvVx0YvEKKZ {\n  width: 100%;\n}\n.UOFl1DgC8woHEsD9WWah {\n  width: 30%;\n}\n.Hbe5orEpR_6b9WbbbxwQ {\n  width: 25%;\n}\n.rgxHpyYPOehXDjmE_AEz {\n  width: 15%;\n}\n.ZXitH7qMn6UxPhZtVw4C {\n  width: 10%;\n}\n.ZXLmg3yWillWS_qJnuVm {\n  width: 10%;\n}\n.HYTIfGAELnmKj9MQnPKj {\n  width: 15%;\n}\n\n.qPhDpmOw0AFs5CJdsKUv {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/categories-page/categories-box/categories-box.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".loader__box {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.box {\n  width: 100%;\n}\n.name {\n  width: 30%;\n}\n.section {\n  width: 25%;\n}\n.subcategories__lenght {\n  width: 15%;\n}\n.posts {\n  width: 10%;\n}\n.likes {\n  width: 10%;\n}\n.date {\n  width: 15%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader__box": "Bid5wY7fSgONRJTfVzJn",
	"box": "aCzRVnUu4YvVx0YvEKKZ",
	"name": "UOFl1DgC8woHEsD9WWah",
	"section": "Hbe5orEpR_6b9WbbbxwQ",
	"subcategories__lenght": "rgxHpyYPOehXDjmE_AEz",
	"posts": "ZXitH7qMn6UxPhZtVw4C",
	"likes": "ZXLmg3yWillWS_qJnuVm",
	"date": "HYTIfGAELnmKj9MQnPKj",
	"title": "qPhDpmOw0AFs5CJdsKUv"
};
export default ___CSS_LOADER_EXPORT___;
