import React from 'react'
import SectionsPage from '../../components/catalog-page/sections-page/sections-page'

const Sections = () => {
  document.title = 'Админка - Разделы'
  return (
    <SectionsPage />
  )
}

export default Sections