import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createSubcategory, deleteSubcategory, editSubcategory, getAllSubcategories, getOneSubcategory } from "../api/catalog/subcategory"
import store from "../redux"
import { closeLeftModal } from "../redux/slices/modal"

const useOneSubcategoryQuery = (id: string) => {
  return useQuery({
    queryFn: () => getOneSubcategory(id),
    queryKey: ['subcategory']
  })
}

const useSubcategoryQuery = () => {
  return useQuery({
    queryFn: () => getAllSubcategories(),
    queryKey: ['subcategories']
  })
}

const useSubcategoryCreate = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: createSubcategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['subcategories']
      })
      client.invalidateQueries({
        queryKey: ['category']
      })
    }
  })
}

const useSubcategoryEdit = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: editSubcategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['subcategory']
      })
      store.dispatch(closeLeftModal())
    }
  })
}

const useSubcategoryDelete = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: deleteSubcategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['subcategories']
      })
      client.invalidateQueries({
        queryKey: ['category']
      })
    }
  })
}

export { useSubcategoryQuery, useSubcategoryCreate, useSubcategoryDelete, useOneSubcategoryQuery, useSubcategoryEdit }