// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OHHyWihQtRVA56pXRiqG {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.hPfzYTXPZW3Glg39TLX4 {\n  width: 400px;\n  background: white;\n  border-radius: 8px;\n  padding: 50px 30px;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.hPfzYTXPZW3Glg39TLX4 input {\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/components/login-page/login-page.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".box {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.form {\n  width: 400px;\n  background: white;\n  border-radius: 8px;\n  padding: 50px 30px;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.form input {\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "OHHyWihQtRVA56pXRiqG",
	"form": "hPfzYTXPZW3Glg39TLX4"
};
export default ___CSS_LOADER_EXPORT___;
