import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import { IShop } from "../../types/shop";
import { CreateShopDto } from "./createShop.dto";

const instanse = axios.create({
  baseURL: `${BASE_URL}/shop`
})

export const getOneShop = async (id: string | number): Promise<IShop> => {
  const res = await instanse.get<IShop>(`${id}`)
  return res.data
}

export const getAllShop = async (): Promise<IShop[]> => {
  const res = await instanse.get<IShop[]>('')
  return res.data
}

export const createShop = async (data: CreateShopDto): Promise<IShop> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.post<IShop>('create', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}
export const deleteShop = async (id: string | number): Promise<{success: boolean, message: string}> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.delete<{success: boolean, message: string}>(`${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}