// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bIAJmNbHLXYhl9FeLGQs {\n  width: 100%;\n}\n\n.mdSpRpwv3wO_tRSt6e0w {\n  width: 25%;\n}\n\n._Cw_WD2slEBsANrlEYh8 {\n  width: 10%;\n}\n\n.AghHgOZQrxJWr5wT4Ddv {\n  width: 15%;\n}\n\n.FszVtTPwV5EiH3xKEu1k {\n  width: 12%;\n}\n\n.vjwsJKyDKhLaFzgCbp8J {\n  width: 18%;\n}\n\n.kX4zyBUFPgfv4mTLE_qQ {\n  width: 10%;\n}\n\n.kKzc66TPeNiXfhDyazAS {\n  width: 10%;\n}\n\n.CGhkzWXeNIzHzOO85Iv2 {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/posts-box/posts-table/posts-table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".box {\n  width: 100%;\n}\n\n.name {\n  width: 25%;\n}\n\n.type {\n  width: 10%;\n}\n\n.user {\n  width: 15%;\n}\n\n.shop {\n  width: 12%;\n}\n\n.subcategory {\n  width: 18%;\n}\n\n.likes {\n  width: 10%;\n}\n\n.date {\n  width: 10%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "bIAJmNbHLXYhl9FeLGQs",
	"name": "mdSpRpwv3wO_tRSt6e0w",
	"type": "_Cw_WD2slEBsANrlEYh8",
	"user": "AghHgOZQrxJWr5wT4Ddv",
	"shop": "FszVtTPwV5EiH3xKEu1k",
	"subcategory": "vjwsJKyDKhLaFzgCbp8J",
	"likes": "kX4zyBUFPgfv4mTLE_qQ",
	"date": "kKzc66TPeNiXfhDyazAS",
	"title": "CGhkzWXeNIzHzOO85Iv2"
};
export default ___CSS_LOADER_EXPORT___;
