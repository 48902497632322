// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eWxDDr_GOwftQNd3eQbr {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n.VBCYRvHrHL01uW2nOxsZ {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n.aw9r1LyhIsmJ0gUym0w4 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 30px 20px 30px;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/subcategories-page/subcategories-page.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".box {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n.title {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n.menu {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 30px 20px 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "eWxDDr_GOwftQNd3eQbr",
	"title": "VBCYRvHrHL01uW2nOxsZ",
	"menu": "aw9r1LyhIsmJ0gUym0w4"
};
export default ___CSS_LOADER_EXPORT___;
