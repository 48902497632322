// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V_uIAX0LR5k33jpW6Jvl {\n  width: 100%;\n}\n\n.NF1xo0_y6EdyQ4geVyM4 {\n  width: 25%;\n}\n.o3y0EZRA6e52B4QdNRIh {\n  width: 25%;\n}\n.LsEhetn9fNUeyodt8sCs {\n  width: 25%;\n}\n.eI38aX6bfmJAbE_ZK2fJ {\n  width: 25%;\n}\n.daSWsrVfPUgOkCMlYHIg {\n  width: 25%;\n}\n.UmnFRXXbBFnXq0ZJqvaK {\n  width: 25%;\n}\n\n.ItCJjQlGILFWREGkFtXF {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/categories-detail-page/subcategories-table/subcategories-table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".box {\n  width: 100%;\n}\n\n.name {\n  width: 25%;\n}\n.section {\n  width: 25%;\n}\n.subcategories__lenght {\n  width: 25%;\n}\n.posts {\n  width: 25%;\n}\n.likes {\n  width: 25%;\n}\n.date {\n  width: 25%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "V_uIAX0LR5k33jpW6Jvl",
	"name": "NF1xo0_y6EdyQ4geVyM4",
	"section": "o3y0EZRA6e52B4QdNRIh",
	"subcategories__lenght": "LsEhetn9fNUeyodt8sCs",
	"posts": "eI38aX6bfmJAbE_ZK2fJ",
	"likes": "daSWsrVfPUgOkCMlYHIg",
	"date": "UmnFRXXbBFnXq0ZJqvaK",
	"title": "ItCJjQlGILFWREGkFtXF"
};
export default ___CSS_LOADER_EXPORT___;
