import React from 'react'
import styles from './loader.module.css'

interface LoaderProps {
  color?: string
}

export const Loader = (props: LoaderProps) => {
  return (
    <span
      className={`${
        props.color === 'white' ? styles.loaderWhite : styles.loader
      }`}
    />
  )
}
