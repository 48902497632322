import { createSlice } from "@reduxjs/toolkit"
import { PayloadAction } from '@reduxjs/toolkit'

import { IUser } from '../../types/user'

interface IInitialState {
  user: IUser | null,
  loading: boolean,
  error: boolean,
  authChecked: boolean
}

const initialState: IInitialState = {
  user: null,
  loading: false,
  error: false,
  authChecked: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoginRequest(state: IInitialState) {
      state.loading = true
      state.error = false
    },
    userLoginError(state: IInitialState) {
      state.loading = false
      state.error = true
    },
    addUser(state: IInitialState, action: PayloadAction<IUser>) {
      state.user = action.payload
      state.loading = false
      state.error = false
    },
    deleteUser(state: IInitialState) {
      state.user = null
    },
    authCheckSuccess(state: IInitialState, action: PayloadAction<IUser>) {
      state.authChecked = true
      state.user = action.payload
    },
    authCheckFailed(state: IInitialState) {
      state.authChecked = true
      state.user = null
    }
  }
})

export const { addUser, userLoginRequest, userLoginError, deleteUser, authCheckSuccess, authCheckFailed } = userSlice.actions
export default userSlice.reducer