import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import styles from './action-menu.module.css'

interface ActionMenuProps {
  id: string
  handler: Dispatch<SetStateAction<boolean>>
  banned: boolean
}

const ActionMenu = (props: ActionMenuProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const closeHandler = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as HTMLElement))
        props.handler(false)
    }
    setTimeout(() => document.body.addEventListener('click', closeHandler), 0)
    return () => document.body.removeEventListener('click', closeHandler)
  }, [props])

  const banHanlder = () => {
    if (!props.banned) {
      // siteUserApi.banUser(props.id)
    } else {
      // siteUserApi.unbanUser(props.id)
    }
  }

  return (
    <div className={styles.menu} ref={ref}>
      <div className={styles.item} onClick={banHanlder}>
        {props.banned ? 'Разблокировать' : 'Заблокировать'}
      </div>
    </div>
  )
}

export default ActionMenu