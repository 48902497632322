// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jjuWuF_bvsAW_qTyB4wj {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n.jjuWuF_bvsAW_qTyB4wj input {\n  width: 100%;\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/categories-detail-page/edit-form/edit-form.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".edit {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n.edit input {\n  width: 100%;\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit": "jjuWuF_bvsAW_qTyB4wj"
};
export default ___CSS_LOADER_EXPORT___;
