import { createSlice } from "@reduxjs/toolkit"

interface IInitialState {
  isLeftModalOpen: boolean,
}

const initialState: IInitialState = {
  isLeftModalOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openLeftModal(state: IInitialState) {
      state.isLeftModalOpen = true
    },
    closeLeftModal(state: IInitialState) {
      state.isLeftModalOpen = false
    }
  }
})

export const {
  openLeftModal,
  closeLeftModal
} = modalSlice.actions
export default modalSlice.reducer