import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TStats } from '../../services/types/stats.type'
import { statsApi } from '../../services/api/stats.api'

import { Loader } from '../ui'
import styles from './home-page.module.css'
import UserCharts from './user-charts/user-charts'

const HomePage = () => {
  const [ stats, setStats ] = useState<TStats>()
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    const fetchStats = () => {
      setLoading(true)
      statsApi
        .getAllStat()
        .then((res) => {
          setStats(res.data)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
    fetchStats()
  }, [])

  if (loading || !stats) return (
    <div className={styles.loader}>
      <Loader />
    </div>
  )

  return (
    <main className={styles.box}>
      <div className={styles.title}>Статистика</div>
      <div className={styles.stats}>
        <Link to={'/users'} className={styles.item}>
          <div className={styles.name}>Пользователи</div>
          <div className={styles.catalog}>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Всего</div>
              <div className={styles.catalog__number}>
                {stats.usersCountAll}
              </div>
            </div>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Сегодня</div>
              <div className={styles.catalog__number}>
                {stats.usersCountToday}
              </div>
            </div>
          </div>
        </Link>
        <Link to={'/catalog/sections'} className={styles.item}>
          <div className={styles.name}>Каталог</div>
          <div className={styles.catalog}>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Разделы</div>
              <div className={styles.catalog__number}>
                {stats.catalog.sectionCountAll}
              </div>
            </div>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Категории</div>
              <div className={styles.catalog__number}>
                {stats.catalog.categoryCountAll}
              </div>
            </div>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Подкатегории</div>
              <div className={styles.catalog__number}>
                {stats.catalog.subcategoryCountAll}
              </div>
            </div>
          </div>
        </Link>
        <Link to={'/shops'} className={styles.item}>
          <div className={styles.name}>Магазины</div>
          <div className={styles.stat}>{stats.shopsCountAll}</div>
        </Link>
        <Link to={'/posts'} className={styles.item}>
          <div className={styles.name}>Посты</div>
          <div className={styles.catalog}>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Всего</div>
              <div className={styles.catalog__number}>
                {stats.postsCountAll}
              </div>
            </div>
            <div className={styles.catalog__item}>
              <div className={styles.catalog__name}>Сегодня</div>
              <div className={styles.catalog__number}>
                {stats.postsCountToday}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <UserCharts count={stats.usersCountAll} new={stats.usersCountToday} />
    </main>
  )
}

export default HomePage