// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qlWKgYHZZdnkZF5SOuGF {\n  position: relative;\n  width: 350px;\n  height: 35px;\n  padding-left: 30px;\n}\n\n.qlWKgYHZZdnkZF5SOuGF span {\n  position: absolute;\n  top: 8px;\n  right: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8c8c8c;\n  font-size: 18px;\n}\n\n.qlWKgYHZZdnkZF5SOuGF input {\n  width: 100%;\n  height: 100%;\n  padding: 10px 35px 10px 10px;\n  box-sizing: border-box;\n  border: 1px solid #e0e2e8;\n  border-radius: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/search/search.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".search {\n  position: relative;\n  width: 350px;\n  height: 35px;\n  padding-left: 30px;\n}\n\n.search span {\n  position: absolute;\n  top: 8px;\n  right: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #8c8c8c;\n  font-size: 18px;\n}\n\n.search input {\n  width: 100%;\n  height: 100%;\n  padding: 10px 35px 10px 10px;\n  box-sizing: border-box;\n  border: 1px solid #e0e2e8;\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "qlWKgYHZZdnkZF5SOuGF"
};
export default ___CSS_LOADER_EXPORT___;
