// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hHPcRuuUYDdYAi5FbELF {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.GcNseztDbwf3xTpBtpqW {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n\n.yEufWCz9FnyDWObTOGVq {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-right: 30px;\n}", "",{"version":3,"sources":["webpack://./src/pages/shops/shops-page/shops-page.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".box {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.title {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n\n.menu {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "hHPcRuuUYDdYAi5FbELF",
	"title": "GcNseztDbwf3xTpBtpqW",
	"menu": "yEufWCz9FnyDWObTOGVq"
};
export default ___CSS_LOADER_EXPORT___;
