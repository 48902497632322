// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m9e2uvs_E9YO3yTttuM7 {\n  margin-top: 10px;\n  width: calc((100% - 10px) / 2);\n  border-radius: 8px;\n  border: 1px solid var(--block-border-color);\n  background: white;\n  padding: 20px;\n  box-sizing: border-box;\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.xzy5KRV3cMjGAH6Rhb_I {\n  color: var(--main-blue);\n  font-size: 18px;\n  margin-bottom: 8px;\n}\n\n.YUK_WwrzgFYaBZgtTsKa {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.A_n3jXe6qBTmavMP3tNq {\n  font-weight: 600;\n  font-size: 28px;\n}\n.SSgVGwjbpMf26W4breYg {\n  margin-top: 5px;\n  font-size: 18px;\n  color: #90da95;\n}", "",{"version":3,"sources":["webpack://./src/components/home-page/user-charts/user-charts.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,kBAAkB;EAClB,2CAA2C;EAC3C,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;EACf,cAAc;AAChB","sourcesContent":[".item {\n  margin-top: 10px;\n  width: calc((100% - 10px) / 2);\n  border-radius: 8px;\n  border: 1px solid var(--block-border-color);\n  background: white;\n  padding: 20px;\n  box-sizing: border-box;\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.title {\n  color: var(--main-blue);\n  font-size: 18px;\n  margin-bottom: 8px;\n}\n\n.stat {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.count {\n  font-weight: 600;\n  font-size: 28px;\n}\n.new {\n  margin-top: 5px;\n  font-size: 18px;\n  color: #90da95;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "m9e2uvs_E9YO3yTttuM7",
	"title": "xzy5KRV3cMjGAH6Rhb_I",
	"stat": "YUK_WwrzgFYaBZgtTsKa",
	"count": "A_n3jXe6qBTmavMP3tNq",
	"new": "SSgVGwjbpMf26W4breYg"
};
export default ___CSS_LOADER_EXPORT___;
