import React from 'react'
import { useSectionQuery } from '../../../../services/hooks/sectionQuery'
import { useCategoryCreate } from '../../../../services/hooks/categoryQuery'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createCategoryDto } from '../../../../services/api/catalog/category/dto/createCategory.dto'

import { Button } from '../../../ui'
import styles from './create-form.module.css'

const CreateForm = () => {
  const { isLoading, isSuccess, data } = useSectionQuery()
  const { mutate: create } = useCategoryCreate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<createCategoryDto>()

  const createCategory: SubmitHandler<createCategoryDto> = async (data) => {
    create(data)
    reset()
  }

  return (
    <form className={styles.create} onSubmit={handleSubmit(createCategory)}>
      <input type="text" placeholder="Имя" {...register('name')} />
      <input type="text" placeholder="Ссылка" {...register('link')} />
      <select
        className={styles.select}
        disabled={isLoading && !isSuccess}
        {...register('sectionId')}
      >
        <option value={''} disabled selected>
          Выберите раздел
        </option>
        {isSuccess &&
          data &&
          data.map((section) => (
            <option key={section.id} value={section.id}>
              {section.name}
            </option>
          ))}
      </select>

      <Button name="Создать" type="secondary" />
    </form>
  )
}

export default CreateForm