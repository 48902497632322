import React from 'react'
import { useForm } from 'react-hook-form'
import { createSectionDto } from '../../../../services/api/catalog/section/dto/createSection.dto'
import { useSectionCreate } from '../../../../services/hooks/sectionQuery'

import { Button } from '../../../ui'
import styles from './create-form.module.css'

const CreateForm = () => {
  const {
    register, handleSubmit, reset
  } = useForm<createSectionDto>()

  const { mutate: create } = useSectionCreate()
  const createHandler = async (data: createSectionDto) => {
    create(data)
    reset()
  }

  return (
    <form className={styles.create} onSubmit={handleSubmit(createHandler)}>
      <input type="text" placeholder="Имя" {...register('name')} />
      <input type="text" placeholder="Ссылка" {...register('link')} />
      <Button name="Создать" type="secondary" />
    </form>
  )
}

export default CreateForm