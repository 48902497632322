import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { IPost } from '../../../services/types/post'

import { Loader } from '..'
import { PostsTable } from './posts-table/posts-table'
import styles from './posts-box.module.css'

interface PostsBox {
  url: string
}

const PostsBox = (props: PostsBox) => {
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<IPost[]>([])

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true)
      const response = await axios.get<IPost[]>(props.url)
      setPosts(response.data)
      setLoading(false)
    }
    fetchPost()
  }, [props.url])

  if (loading)
    return (
      <div className={styles.loader__box}>
        <Loader />
      </div>
    )

  return posts.length > 0 ? <PostsTable posts={posts} /> : <div>Постов нет</div>
}

export default PostsBox