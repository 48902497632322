import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, PostsBox } from '../../ui'
import styles from './posts-page.module.css'

const PostsPage = () => {
  const [ postlength, setPostlenght ] = useState(0)
  const navigate = useNavigate()

  const goToPostCreate = () => {
    navigate('/posts/create')
  }

  return (
    <main className={styles.box}>
      <div className={styles.title}>Посты</div>
      <div className={styles.create__box}>
        Всего постов: {postlength}
        <Button onClick={goToPostCreate} name="Создать пост" type="primary" />
      </div>
      <PostsBox url={'https://api.skidki.promo/post'} />
    </main>
  )
}

export default PostsPage