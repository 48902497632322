// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YgKZzapiJbojQaaQ206a {\n  border-top: 1px solid #f1f1f1;\n  padding: 15px 30px;\n  box-sizing: border-box;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  font-size: 15px;\n}\n\n.YgKZzapiJbojQaaQ206a:hover {\n  background: #f5f7ff;\n}\n\n.rebvZsxSw2Kj4HxkZ5K2 {\n  width: calc(100% - 30px);\n  display: flex;\n}\n.mmT08EIVzWF_rFFmruuJ {\n  width: 33%;\n  color: #316cec;\n}\n.mJlUxmbzpgB4bd4ZqAp4 {\n  width: 33%;\n}\n.ytWHFUPZvVBmqWMmDaK6 {\n  box-sizing: border-box;\n  width: 33%;\n}\n.K_fkahhV1vk9UefI32g0 {\n  visibility: hidden;\n  font-size: 18px;\n  width: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n.K_fkahhV1vk9UefI32g0:hover {\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/sections-page/sections-box/section-block/section-block.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,aAAa;AACf;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,UAAU;AACZ","sourcesContent":[".box {\n  border-top: 1px solid #f1f1f1;\n  padding: 15px 30px;\n  box-sizing: border-box;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  white-space: nowrap;\n  font-size: 15px;\n}\n\n.box:hover {\n  background: #f5f7ff;\n}\n\n.naming {\n  width: calc(100% - 30px);\n  display: flex;\n}\n.name {\n  width: 33%;\n  color: #316cec;\n}\n.categories__lenght {\n  width: 33%;\n}\n.date {\n  box-sizing: border-box;\n  width: 33%;\n}\n.delete {\n  visibility: hidden;\n  font-size: 18px;\n  width: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n.delete:hover {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "YgKZzapiJbojQaaQ206a",
	"naming": "rebvZsxSw2Kj4HxkZ5K2",
	"name": "mmT08EIVzWF_rFFmruuJ",
	"categories__lenght": "mJlUxmbzpgB4bd4ZqAp4",
	"date": "ytWHFUPZvVBmqWMmDaK6",
	"delete": "K_fkahhV1vk9UefI32g0"
};
export default ___CSS_LOADER_EXPORT___;
