import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Loader } from '../../../components/ui'

import ImgBox from './img-box/img-box'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { PostsTable } from '../../../components/ui/posts-box/posts-table/posts-table'
import styles from './shops-detail-page.module.css'
import { useOneShopQuery } from '../../../services/hooks/shopQuery'

const ShopsDetailPage = () => {
  const { id } = useParams()
  const { isLoading, isSuccess, data: shop } = useOneShopQuery(id!)

  if (isLoading) return (
    <div className={styles.loader__box}>
      <Loader />
    </div>
  )
  if (isSuccess && shop) return (
    <div className={styles.box}>
      <div className={styles.breadcrumbs}>
        <Link to={`/shops`} className={styles.link}>
          Магазины
        </Link>
        <div>/</div>
        <div>{shop.name}</div>
      </div>

      <div className={styles.heading}>
        <ImgBox src={shop.img} alt={shop.name} id={String(shop.id)} />
        <div className={styles.heading__info}>
          <div className={styles.title__box}>
            <div className={styles.title}>{shop.name}</div>
          </div>
          <div className={styles.info}>
            <div className={styles.info__item}>
              <p className={styles.info__title}>Ссылка:</p>
              <a
                href={shop.link}
                target={'_blank'}
                rel="noreferrer"
                className={styles.link}
              >
                {shop.link}
              </a>
            </div>
            <div className={styles.info__item}>
              <p className={styles.info__title}>Ссылка на сайте:</p>
              <p className={styles.link}>{shop.siteLink}</p>
            </div>
            <div className={styles.info__item}>
              <p className={styles.info__title}>Колличество постов:</p>
              <p className={styles.link}>{shop.postsCount}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.subtitle}>
        <div className={styles.subtitle__item}>Публикации</div>
      </div>

      {shop && shop.posts && shop.posts.length > 0 ? (
        <PostsTable posts={shop.posts} />
      ) : (
        <div className={styles.sbox}>
          <div className={styles.nosubcategories}>
            <div className={styles.sicon}>
              <AiOutlineQuestionCircle />
            </div>
            <p>
              Публикации отсутствуют. <span>Хочешь создать?</span>
            </p>
          </div>
        </div>
      )}
    </div>
  )
  return <Navigate to={'/shops'}/>
}

export default ShopsDetailPage