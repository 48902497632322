import axios from 'axios'

const instanse = axios.create({
  baseURL: 'https://api.skidki.promo/img/'
})

export const imgApi = {
  async upload(data: any) {
    try {
      const token = localStorage.getItem('token')
      if (!token) throw new Error('Токен отсутствует')
      const res = await instanse.post('/upload', data, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        }
      })
      if (res.statusText !== 'OK') throw new Error ('Ошибка')
      return res.data
    } catch (error: any) {
      console.log(`Сообщение из апи: ${error.message}`)
      return null
    }
  },

  async uploadShopImg(data: FormData, id: string) {
    const token = localStorage.getItem('accessToken')
    const res = await axios.patch(`https://api.skidki.promo/shop/edit/${id}`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }
    })
    return res
  }
}