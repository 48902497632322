import axios from "axios"
import { BASE_URL } from "../../constants/constants"
import { getUserDto } from "./users.dto"

const URL = `${BASE_URL}/user`

export const getAllUsers = async (): Promise<getUserDto> => {
  const token = localStorage.getItem('accessToken')
  const res = await axios.get<getUserDto>(URL, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res.data.success) throw new Error('Ошибка')
  return res.data
}