// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q1Gka_Af0ZZa2YOBnMzg {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ndXvLsXFP_xPx_E21rAI {\n  width: 100%;\n}\n.FzyEZVIssRQ1gfKg7pxp {\n  width: 25%;\n}\n.pAiqqJtkmcnGilCPHXqJ {\n  width: 25%;\n}\n.TKMS8nc_A3n0iC9wpfkS {\n  width: 15%;\n}\n.RmtTqCtl2GPjwYNCOYeL {\n  width: 15%;\n}\n.oxAgX20kIx81UMMa8HhL {\n  width: 20%;\n}\n\n.ffaR_IQEet_1XQL65uii {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/subcategories-page/subcategories-box/subcategories-box.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".loader__box {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.box {\n  width: 100%;\n}\n.name {\n  width: 25%;\n}\n.category {\n  width: 25%;\n}\n.posts {\n  width: 15%;\n}\n.likes {\n  width: 15%;\n}\n.date {\n  width: 20%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader__box": "Q1Gka_Af0ZZa2YOBnMzg",
	"box": "ndXvLsXFP_xPx_E21rAI",
	"name": "FzyEZVIssRQ1gfKg7pxp",
	"category": "pAiqqJtkmcnGilCPHXqJ",
	"posts": "TKMS8nc_A3n0iC9wpfkS",
	"likes": "RmtTqCtl2GPjwYNCOYeL",
	"date": "oxAgX20kIx81UMMa8HhL",
	"title": "ffaR_IQEet_1XQL65uii"
};
export default ___CSS_LOADER_EXPORT___;
