import React from 'react'
import { useForm } from 'react-hook-form'
import { useSectionEdit } from '../../../../services/hooks/sectionQuery'
import { createSectionDto } from '../../../../services/api/catalog/section/dto/createSection.dto'

import { Button } from '../../../ui'
import styles from './edit-form.module.css'

interface EditFormProps {
  name: string,
  link: string,
  id: string | number
}
const EditForm = (props: EditFormProps) => {
  const { register, handleSubmit } = useForm<createSectionDto>()
  const { isLoading, mutate } = useSectionEdit()

  const edit = (data: createSectionDto) => {
    mutate({ id: props.id, data })
  }

  return (
    <form className={styles.edit} onSubmit={handleSubmit(edit)}>
      <input
        type="text"
        placeholder="Название"
        {...register('name', {
          value: props.name,
        })}
      />
      <input
        type="text"
        placeholder="Ссылка"
        {...register('link', {
          value: props.link,
        })}
      />
      <Button name="Отправить" type="primary" loading={isLoading} />
    </form>
  )
}

export default EditForm