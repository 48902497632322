import React from 'react'
import { useSelector } from '../../services/hooks/reduxHooks'
import { Navigate, Outlet } from 'react-router-dom'
import { Loader } from '../ui'

const UnProtectedRoute: React.FC = () => {
  const user = useSelector((state) => state.user.user)
  const { authChecked } = useSelector((state) => state.user)

  return (
    <div>
      {authChecked ? (
        user ? (
          <div>
            <Navigate to="/" />
          </div>
        ) : (
          <Outlet />
        )
      ) : (
        <div style={{display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center'}}><Loader /></div>
      )}
    </div>
  )
}

export default UnProtectedRoute