// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RJJhHjUhG7qolRASWC31 {\n  box-sizing: border-box;\n  display: flex;\n  gap: 10px;\n}\n\n.RJJhHjUhG7qolRASWC31 input {\n  width: 300px;\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/sections-page/create-form/create-form.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB","sourcesContent":[".create {\n  box-sizing: border-box;\n  display: flex;\n  gap: 10px;\n}\n\n.create input {\n  width: 300px;\n  height: 35px;\n  border-radius: 5px;\n  border: 1px solid #d1d1d1;\n  padding: 0 10px;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create": "RJJhHjUhG7qolRASWC31"
};
export default ___CSS_LOADER_EXPORT___;
