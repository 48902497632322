// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avstI8W2E7sbFw2m9H28 {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.u40VdgEipASDY3xWghjT {\n  padding: 30px 0 24px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n\n.W_xYBBKGQKKorHjFpor2 {\n  height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 30px;\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/posts-page/posts-page/posts-page.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".box {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.title {\n  padding: 30px 0 24px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}\n\n.create__box {\n  height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 30px;\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "avstI8W2E7sbFw2m9H28",
	"title": "u40VdgEipASDY3xWghjT",
	"create__box": "W_xYBBKGQKKorHjFpor2"
};
export default ___CSS_LOADER_EXPORT___;
