import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createShop, deleteShop, getAllShop, getOneShop } from "../api/shops"

const useOneShopQuery = (id: string) => {
  return useQuery({
    queryFn: () => getOneShop(id),
    queryKey: ['shop'],
    retry: false
  })
}

const useShopQuery = () => {
  return useQuery({
    queryFn: () => getAllShop(),
    queryKey: ['shops']
  })
}

const useShopCreate = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: createShop,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['shops']
      })
    }
  })
}

const useShopDelete = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: deleteShop,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['shops']
      })
    }
  })
}

export { useShopQuery, useShopCreate, useShopDelete, useOneShopQuery }