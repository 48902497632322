// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sofxydhZ27VTOp_JMgR6 {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.wYXBdMWdqWjTlXTQ4d5X {\n  width: 100%;\n}\n.OhzmrOhY9C8DGT22gwQQ {\n  width: 33%;\n}\n.Td97CLJSQTz_hKProEYQ {\n  width: 33%;\n}\n.DnSpGpfic40TAAczQVGJ {\n  width: 33%;\n}\n\n.MqcEHWTL60RPwK31ujB0 {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/catalog-page/sections-page/sections-box/sections-box.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".loader__box {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.box {\n  width: 100%;\n}\n.name {\n  width: 33%;\n}\n.categories__lenght {\n  width: 33%;\n}\n.date {\n  width: 33%;\n}\n\n.title {\n  width: calc(100% - 30px);\n  padding: 15px 30px 15px 30px;\n  box-sizing: border-box;\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader__box": "sofxydhZ27VTOp_JMgR6",
	"box": "wYXBdMWdqWjTlXTQ4d5X",
	"name": "OhzmrOhY9C8DGT22gwQQ",
	"categories__lenght": "Td97CLJSQTz_hKProEYQ",
	"date": "DnSpGpfic40TAAczQVGJ",
	"title": "MqcEHWTL60RPwK31ujB0"
};
export default ___CSS_LOADER_EXPORT___;
