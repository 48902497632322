import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOneCategoryQuery } from '../../../services/hooks/categoryQuery'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import SubcategoriesTable from './subcategories-table/subcategories-table'
import { createSubcategoryDto } from '../../../services/api/catalog/subcategory/dto/createSubcategory.dto'
import { useSubcategoryCreate } from '../../../services/hooks/subcategoryQuery'
import { useDispatch, useSelector } from '../../../services/hooks/reduxHooks'
import { openLeftModal } from '../../../services/redux/slices/modal'

import { Button, Loader } from '../../ui'
import styles from './categories-detail-page.module.css'
import LeftModal from '../../ui/left-modal/left-modal'
import EditForm from './edit-form/edit-form'

const CategoriesDetailPage = () => {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(store => store.modal.isLeftModalOpen)
  const { id } = useParams()
  const { isLoading, data: category, isSuccess } = useOneCategoryQuery(id!)
  const { mutate: create } = useSubcategoryCreate()
  const { register, handleSubmit, reset } = useForm<createSubcategoryDto>()

  const createSubcategory = async (data: createSubcategoryDto) => {
    create({...data, categoryId: id!})
    reset()
  }

  if (isLoading)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    )

  if (isSuccess && category) return (
    <div className={styles.box}>
      {isModalOpen && (
        <LeftModal title={'Редактировать'}>
          <EditForm name={category.name} link={category.link} id={id!} />
        </LeftModal>
      )}
      <div className={styles.breadcrumbs}>
        <Link to={`/catalog/categories`} className={styles.link}>
          Категории
        </Link>
        <div>/</div>
        <div>{category.name}</div>
      </div>
      <div className={styles.title__box}>
        <div className={styles.title}>{category.name}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.info__item}>
          <p className={styles.info__title}>Раздел:</p>
          <Link
            to={`/catalog/sections/${category.section.id}`}
            className={styles.link}
          >
            {category.section.name}
          </Link>
        </div>
        <div className={styles.info__item}>
          <p className={styles.info__title}>Колличество подкатегорий:</p>
          <p className={styles.link}>{category.subcategories?.length}</p>
        </div>
        <div className={styles.info__item}>
          <p className={styles.info__title}>Ссылка:</p>
          <p className={styles.link}>{category.link}</p>
        </div>
        <div
          className={styles.edit}
          onClick={() => {
            dispatch(openLeftModal())
          }}
        >
          Редактировать
        </div>
      </div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle__item}>Подкатегории</div>
      </div>
      <div className={styles.subcategories__action}>
        <form
          className={styles.create}
          onSubmit={handleSubmit(createSubcategory)}
        >
          <input type="text" placeholder="Название" {...register('name')} />
          <input type="text" placeholder="Ссылка" {...register('link')} />
          <Button name="Создать" type="secondary" />
        </form>
      </div>
      {category.subcategories && category.subcategories?.length !== 0 ? (
        <SubcategoriesTable subcategories={category.subcategories} />
      ) : (
        <div className={styles.sbox}>
          <div className={styles.nosubcategories}>
            <div className={styles.sicon}>
              <AiOutlineQuestionCircle />
            </div>
            <p>
              Подкатегории отсутствуют. <span>Хочешь создать?</span>
            </p>
          </div>
        </div>
      )}
    </div>
  )

  return <Navigate to={'/catalog/categories'}/>
}

export default CategoriesDetailPage