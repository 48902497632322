import React from 'react'
import { useSectionQuery } from '../../../../services/hooks/sectionQuery'

import SectionBlock from './section-block/section-block'
import { Loader } from '../../../ui'

import styles from './sections-box.module.css'

interface SectionsBoxProps {
  filter: string
}

const SectionsBox = ({ filter }: SectionsBoxProps) => {
  const { isLoading, data, isSuccess } = useSectionQuery()

  if (isLoading)
    return (
      <div className={styles.loader__box}>
        <Loader />
      </div>
    )

  if (isSuccess && data) return data.length > 1 ? (
    <div className={styles.box}>
      <div className={styles.title}>
        <div className={styles.name}>Имя</div>
        <div className={styles.categories__lenght}>Кол-во категорий</div>
        <div className={styles.date}>Дата создания</div>
      </div>
      {data
        .filter((section) =>
          section.name.toLowerCase().includes(filter.toLowerCase())
        )
        .map((section) => (
          <SectionBlock section={section} key={section.id} />
        ))}
    </div>
  ) : (
    <div>Секций нет</div>
  )

  return <div className={styles.box}>Секций нет</div>
}

export default SectionsBox