// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SY_UULXjCCxEzFx6ncMA {\n  width: 25px;\n  height: 25px;\n  border: 2px solid #0051ff;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: zdM6GSod28svIya5yvvh 0.7s linear infinite;\n}\n.Wx3QICM6az3wcGgeQQah {\n  width: 25px;\n  height: 25px;\n  border: 2px solid #ffffff;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: zdM6GSod28svIya5yvvh 0.7s linear infinite;\n}\n\n@keyframes zdM6GSod28svIya5yvvh {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/ui/loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,oDAAwC;AAC1C;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,oDAAwC;AAC1C;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  width: 25px;\n  height: 25px;\n  border: 2px solid #0051ff;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 0.7s linear infinite;\n}\n.loaderWhite {\n  width: 25px;\n  height: 25px;\n  border: 2px solid #ffffff;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 0.7s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "SY_UULXjCCxEzFx6ncMA",
	"rotation": "zdM6GSod28svIya5yvvh",
	"loaderWhite": "Wx3QICM6az3wcGgeQQah"
};
export default ___CSS_LOADER_EXPORT___;
