import React from 'react'
import { NavLink } from 'react-router-dom'
import { userApi } from '../../services/api/user.api'
import { useDispatch } from '../../services/hooks/reduxHooks'

import {
} from 'react-icons/ai'

import {
  MdOutlineSpaceDashboard,
  MdOutlineMonitor,
  MdPeople,
  MdAlternateEmail,
  MdOutlineShoppingBag,
  MdOutlineTextSnippet,
  MdOutlineSettings,
  MdOutlineLogout,
} from 'react-icons/md'
import SectionButton from './section-button/section-button'

import styles from './menu.module.css'

const Menu = React.memo(() => {
  const dispatch = useDispatch()
  const logOut = () => {
    userApi.logout(dispatch)
  }

  return (
    <nav className={styles.box}>
      <div className={styles.logo}>Скидки и Промокоды</div>

      <div className={styles.body}>
        <div className={styles.menu}>
          <NavLink
            to="/"
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdOutlineSpaceDashboard />
            </div>
            <div className={styles.name}>Главная</div>
          </NavLink>

          {/* <NavLink
            to={'/monitoring'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdOutlineMonitor />
            </div>
            <div className={styles.name}>Мониторинг</div>
          </NavLink> */}

          <NavLink
            to={'/users'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdPeople />
            </div>
            <div className={styles.name}>Пользователи</div>
          </NavLink>

          <NavLink
            to={'/email'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdAlternateEmail />
            </div>
            <div className={styles.name}>Email рассылки</div>
          </NavLink>

          <SectionButton />

          <NavLink
            to={'/shops'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdOutlineShoppingBag />
            </div>
            <div className={styles.name}>Магазины</div>
          </NavLink>

          {/* <NavLink
            to={'/cities'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <AiOutlineShop />
            </div>
            <div className={styles.name}>Города</div>
          </NavLink> */}

          <NavLink
            to={'/posts'}
            className={(nav) =>
              nav.isActive ? `${styles.item__active}` : `${styles.item}`
            }
          >
            <div className={styles.icon}>
              <MdOutlineTextSnippet />
            </div>
            <div className={styles.name}>Посты</div>
          </NavLink>
        </div>

        <div className={styles.bottom}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <MdOutlineSettings />
            </div>
            <div className={styles.name}>Настройки</div>
          </div>
          <div className={styles.item} onClick={logOut}>
            <div className={styles.icon}>
              <MdOutlineLogout />
            </div>
            <div className={styles.name}>Выход</div>
          </div>
        </div>
      </div>
    </nav>
  )
})

Menu.displayName = 'Menu'

export default Menu