import React from 'react'
import { ISubcategory } from '../../../../services/types/subcategory.type'
import SubcategoriesItem from './subcategories-item/subcategories-item'

import styles from './subcategories-table.module.css'

interface SubcategoriesTableProps {
  subcategories: ISubcategory[]
}

const SubcategoriesTable = (props: SubcategoriesTableProps) => {
  return (
    <div className={styles.box}>
      <div className={styles.title}>
        <div className={styles.name}>Имя</div>
        <div className={styles.posts}>Посты</div>
        <div className={styles.date}>Дата создания</div>
      </div>
      {props.subcategories.map((sub) => (
        <SubcategoriesItem  key={sub.id} subcategory={sub}/>
      ))}
    </div>
  )
}

export default SubcategoriesTable