import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CreateShopDto } from '../../../../services/api/shops/createShop.dto'

import { Button } from '../../../../components/ui'

import styles from './create-form.module.css'
import { useShopCreate } from '../../../../services/hooks/shopQuery'

const CreateForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateShopDto>()
  const { mutate: create } = useShopCreate()

  const createHandler: SubmitHandler<CreateShopDto> = async (data) => {
    create(data)
    reset()
  }

  return (
    <form className={styles.create} onSubmit={handleSubmit(createHandler)}>
      <input
        type="text"
        placeholder="Название"
        {...register("name")}
      />
      <input
        type="text"
        placeholder="Ссылка"
        {...register("link")}
      />
      <input
        type="text"
        placeholder="Ссылка на сайте"
        {...register("siteLink")}
      />
      <Button name='Создать' type='secondary' />
    </form>
  )
}

export default CreateForm