import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { deleteCategory, getAllCategories, createCategory, getOneCategory, editCategory } from "../api/catalog/category"
import store from "../redux"
import { closeLeftModal } from "../redux/slices/modal"

const useOneCategoryQuery = (id: string | number) => {
  return useQuery({
    queryFn: () => getOneCategory(id),
    queryKey: ['category'],
    cacheTime: 0
  })
}

const useCategoryQuery = () => {
  return useQuery({
    queryFn: () => getAllCategories(),
    queryKey: ['categories'],
    cacheTime: 0
  })
}

const useCategoryCreate = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: createCategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['categories']
      })
      client.invalidateQueries({
        queryKey: ['section']
      })
    }
  })
}

const useCategoryEdit = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: editCategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['category']
      })
      store.dispatch(closeLeftModal())
    }
  })
}

const useCategoryDelete = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['categories']
      })
      client.invalidateQueries({
        queryKey: ['section']
      })
    }
  })
}

export { useCategoryQuery, useCategoryCreate, useCategoryDelete, useOneCategoryQuery, useCategoryEdit }