import React from 'react'
import { useSelector } from '../../services/hooks/reduxHooks'
import { Navigate, Outlet } from 'react-router-dom'

import { Loader } from '../ui'
import Layout from '../layout/layout'

const ProtectedRoute = () => {
  const user = useSelector((state) => state.user.user)
  const { authChecked } = useSelector((state) => state.user)

  return (
    <>
      {authChecked ? (
        user ? (
          <Layout>
            <Outlet />
          </Layout>
        ) : (
          <Navigate to="/login" />
        )
      ) : (
        <div className='loader__box'><Loader /></div>
      )}
    </>
  )
}

export default ProtectedRoute