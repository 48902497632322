import React from 'react'

import CategoryBlock from './category-block/category-block'
import { Loader } from '../../../ui'

import styles from './categories-box.module.css'
import { useCategoryQuery } from '../../../../services/hooks/categoryQuery'


const CategoriesBox = () => {
  const { isLoading, isSuccess, data } = useCategoryQuery()

  if (isLoading)
    return (
      <div className={styles.loader__box}>
        <Loader />
      </div>
    )

  if (isSuccess && data)return data.length > 0 ? (
    <div className={styles.box}>
      <div className={styles.title}>
        <div className={styles.name}>Имя</div>
        <div className={styles.section}>Раздел</div>
        <div className={styles.subcategories__lenght}>Подкатегории</div>
        <div className={styles.posts}>Посты</div>
        <div className={styles.date}>Дата создания</div>
      </div>
      {data.map((category) => (
        <CategoryBlock category={category} key={category.id} />
      ))}
    </div>
  ) : (
    <div>Категорий нет</div>
  )

  return <div>Ошибка</div>
}

export default CategoriesBox