import React from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from '../../../services/hooks/reduxHooks'
import { useOneSectionQuery } from '../../../services/hooks/sectionQuery'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createCategoryDto } from '../../../services/api/catalog/category/dto/createCategory.dto'
import { useCategoryCreate } from '../../../services/hooks/categoryQuery'
import { openLeftModal } from '../../../services/redux/slices/modal'

import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Button, Loader } from '../../ui'
import CategoriesTable from './categories-table/categories-table'
import LeftModal from '../../ui/left-modal/left-modal'
import EditForm from './edit-form/edit-form'

import styles from './sections-detail-page.module.css'

const SectionsDetailPage = () => {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    reset,
  } = useForm<createCategoryDto>()

  const { id } = useParams()
  const navigate = useNavigate()
  const isModalOpen = useSelector(store => store.modal.isLeftModalOpen)
  const { isLoading, isSuccess, data: section } = useOneSectionQuery(id!)
  const { mutate: create } = useCategoryCreate()

  const createCategory: SubmitHandler<createCategoryDto> = async (data: createCategoryDto) => {
    create({...data, sectionId: id!})
    reset()
  }

  if (isLoading) return <div className={styles.loader}><Loader /></div>
  if (isSuccess && section) return (
    <div className={styles.box}>
      {isModalOpen && <LeftModal title="Редактировать"><EditForm name={section.name} link={section.link} id={section.id} /></LeftModal>}
      <div className={styles.breadcrumbs}>
        <Link to={`/catalog/sections`} className={styles.link}>
          Разделы
        </Link>
        <div>/</div>
        <div>{section.name}</div>
      </div>
      <div className={styles.heading}>
        <div className={styles.heading__info}>
          <div className={styles.title__box}>
            <div className={styles.title}>{section.name}</div>
          </div>
          <div className={styles.info}>
            <div className={styles.info__item}>
              <p className={styles.info__title}>Ссылка:</p>
              <p className={styles.link}>{section.link}</p>
            </div>
            <div className={styles.info__item}>
              <p className={styles.info__title}>Колличество категорий:</p>
              <p className={styles.link}>{section.categories?.length}</p>
            </div>
            <div
              className={styles.edit}
              onClick={() => {
                dispatch(openLeftModal())
              }}
            >
              Редактировать
            </div>
          </div>
        </div>
      </div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle__item}>Категории</div>
      </div>
      <div className={styles.subcategories__action}>
        <form className={styles.create} onSubmit={handleSubmit(createCategory)}>
          <input
            placeholder="Название"
            {...register('name', { required: 'Поле обязательно к заполнению' })}
          />
          <input
            placeholder="Ссылка"
            {...register('link', { required: 'Поле обязательно к заполнению' })}
          />
          <Button name="Создать" type="secondary" />
        </form>
      </div>
      {section.categories && section.categories.length !== 0 ? (
        <CategoriesTable categories={section.categories} />
      ) : (
        <div className={styles.sbox}>
          <div className={styles.nosubcategories}>
            <div className={styles.sicon}>
              <AiOutlineQuestionCircle />
            </div>
            <p>
              Категории отсутствуют. <span>Хочешь создать?</span>
            </p>
          </div>
        </div>
      )}
    </div>
  )
  return <Navigate to='/catalog/sections' />
}

export default SectionsDetailPage