import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router'

import Home from '../../pages/home'
import Monitoring from '../../pages/monitoring'
import Email from '../../pages/email'
import Cities from '../../pages/cities'
import Sections from '../../pages/catalog/sections'
import SectionsDetailPage from '../catalog-page/sections-detail-page/sections-detail-page'
import Categories from '../../pages/catalog/categories'
import CategoriesDetail from '../../pages/catalog/categories-detail'
import Subategories from '../../pages/catalog/subcategories'
import SubcategoriesDetail from '../../pages/catalog/subcategories-detail'
import Posts from '../../pages/posts'
import Login from '../../pages/login'
import PostDetail from '../../pages/posts/post-detail'
import PostInfo from '../posts-page/post-detail-page/post-info/post-info'
import { Users, Shops, ShopDetails } from '../../pages'

import { userApi } from '../../services/api/user.api'
import { useDispatch } from '../../services/hooks/reduxHooks'
import { CatLay } from '../catalog-page/cat-lay/cat-lay'

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute'
import UnProtectedRoute from '../UnProtectedRoute/UnProtectedRoute'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => userApi.checkAuth(dispatch), [dispatch])

  return (
    <div>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/monitoring" element={<Monitoring />} />
          <Route path="/users" element={<Users />} />
          <Route path="/email" element={<Email />} />
          <Route path="catalog" element={<CatLay />}>
            <Route path="sections" element={<Sections />} />
            <Route path="sections/:id" element={<SectionsDetailPage />} />
            <Route path="categories" element={<Categories />} />
            <Route path="categories/:id" element={<CategoriesDetail />} />
            <Route path="subcategories" element={<Subategories />} />
            <Route path="subcategories/:id" element={<SubcategoriesDetail />} />
          </Route>
          <Route path="cities" element={<Cities />} />
          <Route path="posts" element={<Posts />} />
          {/* <Route path="posts/create" element={<Create />} /> */}
          <Route element={<PostDetail />}>
            <Route path="posts/:id/info" element={<PostInfo />} />
            <Route path="posts/:id/comments" element={<PostInfo />} />
          </Route>
          <Route path="shops" element={<Shops />} />
          <Route path="shops/:id" element={<ShopDetails />} />
        </Route>
        <Route element={<UnProtectedRoute />}>
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App