import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from '../../../services/hooks/reduxHooks'
import { closeLeftModal } from '../../../services/redux/slices/modal'

import { MdClear } from 'react-icons/md'
import styles from './left-modal.module.css'

interface LeftModalProps {
  children: React.ReactNode
  title: string
}

const modalRoot = document.getElementById('modal') as HTMLElement
const LeftModal = (props: LeftModalProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dispatch(closeLeftModal())
      }
    }
    document.addEventListener('keydown', close)
    return () => document.removeEventListener('keydown', close)
  }, [])

  return ReactDOM.createPortal(
    <div className={styles.box}>
      <div className={styles.children}>
        <div className={styles.heading}>
          <div className={styles.title}>{props.title}</div>
          <div
            className={styles.icon}
            onClick={() => dispatch(closeLeftModal())}
          >
            <MdClear size={20} />
          </div>
        </div>
        {props.children}
      </div>
      <div
        className={styles.overlay}
        onClick={() => dispatch(closeLeftModal())}
      />
    </div>,
    modalRoot
  )
}

export default LeftModal