import React from 'react'
import { useCategoryQuery } from '../../../../services/hooks/categoryQuery'
import { useSubcategoryCreate } from '../../../../services/hooks/subcategoryQuery'
import { createSubcategoryDto } from '../../../../services/api/catalog/subcategory/dto/createSubcategory.dto'

import { Button } from '../../../ui'
import styles from './create-form.module.css'
import { useForm } from 'react-hook-form'

const CreateForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<createSubcategoryDto>()

  const { data, isSuccess } = useCategoryQuery()
  const { mutate: create } = useSubcategoryCreate()

  const createHandler = async (data: createSubcategoryDto) => {
    create(data)
    reset
  }

  return (
    <form className={styles.create} onSubmit={handleSubmit(createHandler)}>
      <input type="text" placeholder="Имя" {...register('name')} />
      <input type="text" placeholder="Ссылка" {...register('link')}/>
      <select className={styles.select} {...register('categoryId')}>
        <option value="" disabled selected>
          Выберите категорию
        </option>
        {isSuccess &&
          data &&
          data.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
      </select>
      <Button name="Создать" type="secondary" />
    </form>
  )
}

export default CreateForm