import React from 'react'
import { userApi } from '../../services/api/user.api'
import { useDispatch, useSelector } from '../../services/hooks/reduxHooks'
import { SubmitHandler, useForm } from 'react-hook-form'
import { LoginDto } from '../../services/types/user'

import { Button } from '../ui'
import styles from './login-page.module.css'

const LoginPage = () => {
  const loading = useSelector(state => state.user.loading)
  const {
    register,
    handleSubmit,
  } = useForm<LoginDto>()
  const dispatch = useDispatch()

  const login: SubmitHandler<LoginDto> = (data) => {
    userApi.login(data, dispatch)
  }

  return (
    <div className={styles.box}>
      <form className={styles.form} onSubmit={handleSubmit(login)}>
        <input
          required
          type="text"
          autoComplete='email'
          placeholder="Логин"
          {...register('email')}
        />
        <input
          required
          type="password"
          autoComplete="current-password"
          placeholder="Пароль"
          {...register('password')}
        />
        <Button
          type="primary"
          name="Войти"
          loading={loading}
          disabled={loading}
        />
      </form>
    </div>
  )
}

export default LoginPage