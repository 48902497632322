import React from 'react'
import CategoriesPage from '../../components/catalog-page/categories-page/categories-page'

const Categories = () => {
  document.title = 'Админка - Категории'
  return (
    <CategoriesPage />
  )
}

export default Categories