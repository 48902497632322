import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { IShop } from '../../../services/types/shop'

import CreateForm from './create-form/create-form'
import ShopsBox from './shops-box/shops-box'

import styles from './shops-page.module.css'

const ShopsPage = () => {
  return (
    <main className={styles.box}>
      <div className={styles.title}>Магазины</div>
      <div className={styles.menu}>
        <CreateForm />
      </div>
      <ShopsBox />
    </main>
  )
}

export default ShopsPage