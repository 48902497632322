import React from 'react'
import { IPost } from '../../../../services/types/post'

import PostBlock from './post-block/post-block'
import styles from './posts-table.module.css'

interface PostsTableProps {
  posts: IPost[]
}

export const PostsTable = (props: PostsTableProps) => {
  return (
    <div className={styles.box}>
      <div className={styles.title}>
        <div className={styles.name}>Название</div>
        <div className={styles.type}>Тип</div>
        <div className={styles.user}>Пользователь</div>
        <div className={styles.shop}>Магазин</div>
        <div className={styles.subcategory}>Категория</div>
        <div className={styles.likes}>Лайки</div>
        <div className={styles.date}>Дата создания</div>
      </div>
      {props.posts.map((post) => (
        <PostBlock post={post} key={post.id} />
      ))}
    </div>
  )
}