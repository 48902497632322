import React from 'react'

import { AiOutlineRight } from 'react-icons/ai'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './section-button.module.css'
import { MdFolderOpen } from 'react-icons/md'

const SectionButton = React.memo(() => {
  const location = useLocation()
  const isMatch = location.pathname.includes('/catalog')
  const cn = isMatch ? `${styles.open}` : ``
  const menuCn = isMatch ? `${styles.menuopen}` : ``


  return (
    <div className={`${styles.menu} ${menuCn}`}>
      <NavLink to={'/catalog/sections'} className={`${styles.main} ${cn}`}>
        <div className={styles.icon}>
          <MdFolderOpen />
        </div>
        <div className={styles.title}>
          <div className={styles.name}>Каталог</div>
          <div className={styles.title__icon}>
            <AiOutlineRight />
          </div>
        </div>
      </NavLink>
      {isMatch ? (
        <>
          <NavLink
            to={'/catalog/sections'}
            className={(nav) =>
              nav.isActive ? `${styles.item_active}` : `${styles.item}`
            }
          >
            Разделы
          </NavLink>
          <NavLink
            to={'/catalog/categories'}
            className={(nav) =>
              nav.isActive ? `${styles.item_active}` : `${styles.item}`
            }
          >
            Категории
          </NavLink>
          <NavLink
            to={'/catalog/subcategories'}
            className={(nav) =>
              nav.isActive ? `${styles.item_active}` : `${styles.item}`
            }
          >
            Подкатегории
          </NavLink>
        </>
      ) : null}
    </div>
  )
})

SectionButton.displayName = 'SectionButton'

export default SectionButton