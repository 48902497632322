import React from 'react'
import HomePage from '../components/home-page/home-page'

const Home = () => {
  document.title = 'Админка'
  return (
    <HomePage />
  )
}

export default Home