import React from 'react'
import { useSubcategoryQuery } from '../../../../services/hooks/subcategoryQuery'
import { Loader } from '../../../ui'
import SubcategoryBlock from './subcategory-block/subcategory-block'

import styles from './subcategories-box.module.css'

interface SubategoriesBoxProps {
  filter: string
}

const SubategoriesBox = ({ filter }: SubategoriesBoxProps) => {
  const { isLoading, isSuccess, data } = useSubcategoryQuery()

  if (isLoading)
    return (
      <div className={styles.loader__box}>
        <Loader />
      </div>
    )

  if (isSuccess && data) return (
    data.length > 1 ? (
        <div className={styles.box}>
        <div className={styles.title}>
          <div className={styles.name}>Имя</div>
          <div className={styles.category}>Категория</div>
          <div className={styles.posts}>Посты</div>
          <div className={styles.likes}>Лайки</div>
          <div className={styles.date}>Дата создания</div>
        </div>
        {data
          .filter((section) =>
            section.name.toLowerCase().includes(filter.toLowerCase())
          )
          .map((subcategory) => (
            <SubcategoryBlock subcategory={subcategory} key={subcategory.id} />
          ))}
      </div>
    ) : (
      <div>Категорий нет</div>
    )
  )
  return <div>Категорий нет</div>
}

export default SubategoriesBox