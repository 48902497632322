import React from 'react'

import CategoriesBox from './categories-box/categories-box'
import CreateForm from './create-form/create-form'
import { Search } from '../../ui'

import styles from './categories-page.module.css'

const CategoriesPage = () => {

  return (
    <main className={styles.box}>
      <div className={styles.title}>Категории</div>
      <div className={styles.menu}>
        <CreateForm />
      </div>
      <CategoriesBox />
    </main>
  )
}

export default CategoriesPage