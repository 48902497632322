// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fnsFaSWgvPDFdNLTXnvw {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ui/posts-box/posts-box.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".loader__box {\n  top: 0;\n  left: 280px;\n  position: absolute;\n  width: calc(100% - 280px);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader__box": "fnsFaSWgvPDFdNLTXnvw"
};
export default ___CSS_LOADER_EXPORT___;
