import React, { useRef } from 'react'
import { ISection } from '../../../../../services/types/section.type'
import { useNavigate } from 'react-router-dom'

import { AiOutlineDelete } from 'react-icons/ai'

import styles from './section-block.module.css'
import { useSectionDelete } from '../../../../../services/hooks/sectionQuery'

interface IProps {
  section: ISection
}

const SectionBlock = (props: IProps) => {
  const navigate = useNavigate()
  const deleteRef = useRef<HTMLDivElement>(null)
  const { mutate: deletesection } = useSectionDelete()

  const goToSection = () => {
    navigate(`/catalog/sections/${props.section.id}`)
  }

  const deleteHandler = {
    visible: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'visible'
      }
    },
    hide: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'hidden'
      }
    },
    delete: async (e: React.BaseSyntheticEvent) => {
      e.stopPropagation()
      deletesection(props.section.id)
    }
  }

  const date = new Date(props.section.createdAt)
  return (
    <div
      onClick={goToSection}
      className={styles.box}
      onMouseEnter={deleteHandler.visible}
      onMouseLeave={deleteHandler.hide}
    >
      <div className={styles.naming}>
        <div className={styles.name}>{props.section.name}</div>
        <div className={styles.categories__lenght}>
          {props.section.categories.length}
        </div>
        <div className={styles.date}>{date.toLocaleDateString('ru-RU')}</div>
      </div>
      <div
        className={styles.delete}
        ref={deleteRef}
        onClick={deleteHandler.delete}
      >
        <AiOutlineDelete />
      </div>
    </div>
  )
}

export default SectionBlock