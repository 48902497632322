import React from 'react'
import { useForm } from 'react-hook-form'
import { createCategoryDto } from '../../../../services/api/catalog/category/dto/createCategory.dto'
import { useCategoryEdit } from '../../../../services/hooks/categoryQuery'

import { Button } from '../../../ui'
import styles from './edit-form.module.css'

interface EditFormProps {
  name: string,
  link: string,
  id: string | number
}
const EditForm = (props: EditFormProps) => {
  const { register, handleSubmit } = useForm<createCategoryDto>()
  const { isLoading, mutate } = useCategoryEdit()

  const edit = (data: createCategoryDto) => {
    mutate({id: props.id, data})
  }

  return (
    <form className={styles.edit} onSubmit={handleSubmit(edit)}>
      <input
        type="text"
        placeholder="Название"
        {...register('name', {
          value: props.name,
        })}
      />
      <input
        type="text"
        placeholder="Ссылка"
        {...register('link', {
          value: props.link,
        })}
      />
      <Button name="Отправить" type="primary" loading={isLoading} />
    </form>
  )
}

export default EditForm