import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { IPost } from '../../../../services/types/post'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'

import { Loader } from '../../../ui'

import styles from './post-info.module.css'

const PostInfo = () => {
  const id = useOutletContext()
  const navigate = useNavigate()
  const [post, setPost] = useState<IPost>()
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState(false)

  useEffect(() => {
    const getPostInfo = () => {
      setLoading(true)
      axios
        .get<IPost>(`https://api.skidki.promo/post/${id}`)
        .then((res) => {
          setLoading(false)
          setPost(res.data)
        })
        .catch(() => {
          setLoading(false)
          setErr(true)
        })
    }
    getPostInfo()
  }, [id])
  

  if (loading || !post) return (
    <div className={styles.loader__box}>
      <Loader />
    </div>
  )
  if (err) return <div>Err</div>

  const date = new Date(post.createdAt)
  const URL = `https://api.skidki.promo/img/${post.img[0]}`

  return (
    <div className={styles.postInfo}>
      <div className={styles.info}>
        <div className={styles.key}>Название</div>
        <div className={styles.value}>{post.title}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.key}>Дата публикации</div>
        <div className={styles.value}>{date.toLocaleDateString('ru-RU')}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.key}>Пользователь</div>
        <div className={styles.value}>
          <Link className={styles.link} to={`/users/${post.user.id}`}>
            {post.user.username}
          </Link>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.key}>Ссылка</div>
        <div className={styles.value}>
          <a
            className={styles.link}
            href={post.link}
            target="_blank"
            rel="noreferrer"
          >
            {post.link}
          </a>
        </div>
      </div>
      {post.shop ? (
        <div className={styles.info}>
          <div className={styles.key}>Магазин</div>
          <div className={styles.value}>
            <Link className={styles.link} to={`/shops/${post.shop.id}`}>
              {post.shop.name}
            </Link>
          </div>
        </div>
      ) : null}
      {post.town ? (
        <div className={styles.info}>
          <div className={styles.key}>Город</div>
          <div className={styles.value}>{post.town}</div>
        </div>
      ) : null}
      <div className={styles.info}>
        <div className={styles.key}>Цена</div>
        <div className={styles.value}>{post.newPrice} ₽</div>
      </div>
      {post.oldPrice !== null ? (
        <div className={styles.info}>
          <div className={styles.key}>Старая цена</div>
          <div className={styles.value}>{post.oldPrice} ₽</div>
        </div>
      ) : null}
      <div className={styles.info}>
        <div className={styles.key}>Лайки</div>
        <div className={styles.value}>{post.likesCount}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.key}>Тип</div>
        <div className={styles.value}>{post.type}</div>
      </div>
      {post.promocode ? (
        <div className={styles.info}>
          <div className={styles.key}>Промокод</div>
          <div className={styles.value}>{post.promocode}</div>
        </div>
      ) : null}
      {post.type !== 'Скидка' ? (
        <>
          <div className={styles.info}>
            <div className={styles.key}>Тип промокода</div>
            <div className={styles.value}>{post.promocodeType}</div>
          </div>
          {post.promocodeType === 'Рубли' ? (
            <div className={styles.info}>
              <div className={styles.key}>Размер скидки</div>
              <div className={styles.value}>{post.promocodeDiscount}</div>
            </div>
          ) : (
            <div className={styles.info}>
              <div className={styles.key}>Процент скидки</div>
              <div className={styles.value}>{post.promocodePercent}</div>
            </div>
          )}
        </>
      ) : null}
      {post.startAction !== '' ? (
        <div className={styles.info}>
          <div className={styles.key}>Время действия</div>
          <div className={styles.value}>
            <p>{post.startAction}</p> - <p>{post.expiration}</p>
          </div>
        </div>
      ) : null}
      <div className={styles.info}>
        <div className={styles.key}>Раздел</div>
        <div className={styles.value}>
          <p>{post.section?.name}</p>/<p>{post.category?.name}</p>/
          <p>{post.subcategory?.name}</p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.key}>Горячее</div>
        <div className={styles.value}>{post.isHot ? 'Да' : 'Нет'}</div>
      </div>
      <div className={styles.details}>
        <p>Описание</p>
        {post.details}
      </div>
      <div className={styles.details}>
        <p>Изображения</p>
        <div className={styles.img}>
          <img src={URL} alt={`Пост #${id}`} />
        </div>
      </div>
    </div>
  )
}

export default PostInfo