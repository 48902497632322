import axios from "axios";
import { BASE_URL } from "../../../constants/constants";
import { createCategoryDto } from "./dto/createCategory.dto";
import { ICategory } from "../../../types/category.type";

const instanse = axios.create({
  baseURL: `${BASE_URL}/category`
})

export const getOneCategory = async (id: string | number): Promise<ICategory> => {
  const res = await instanse.get<ICategory>(`${id}`)
  return res.data
}

export const getAllCategories = async (): Promise<ICategory[]> => {
  const res = await instanse.get<ICategory[]>('')
  return res.data
}

export const editCategory = async (dto: { data: createCategoryDto, id: string | number }): Promise<ICategory> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.patch<ICategory>(`${dto.id}`, dto.data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const createCategory = async (data: createCategoryDto): Promise<ICategory> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.post<ICategory>('', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const deleteCategory = async (categoryid: string | number): Promise<{success: boolean, message: string}> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.delete<{success: boolean, message: string}>(`${categoryid}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}