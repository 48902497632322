// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SMm9Ynj7ZK5VyxFoov4N {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.X5OsyFfnXXXQyWAm4Ew6 {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}", "",{"version":3,"sources":["webpack://./src/components/cities-page/cities-page.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".box {\n  width: 100%;\n  height: 100vh;\n  overflow: scroll;\n}\n.title {\n  padding: 30px 0 30px 30px;\n  font-size: 22px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "SMm9Ynj7ZK5VyxFoov4N",
	"title": "X5OsyFfnXXXQyWAm4Ew6"
};
export default ___CSS_LOADER_EXPORT___;
