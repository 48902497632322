import axios from 'axios'
import { TStats } from '../types/stats.type'

const instanse = axios.create({
  baseURL: 'https://api.skidki.promo/stats'
})

export const statsApi = {
  getAllStat() {
    const token = localStorage.getItem('accessToken')
    return instanse.get<TStats>('', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}