import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { IShop } from '../../../../../services/types/shop'
import { useShopDelete } from '../../../../../services/hooks/shopQuery'

import { AiOutlineDelete } from 'react-icons/ai'
import styles from './shop-block.module.css'

interface IProps {
  shop: IShop
}

const ShopBlock = ({ shop }: IProps) => {
  const navigate = useNavigate()
  const { mutate: deleteShop } = useShopDelete()
  const deleteRef = useRef<HTMLDivElement>(null)

  const goToShop = () => {
    navigate(`/shops/${shop.id}`)
  }

  const deleteHandler = {
    visible: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'visible'
      }
    },
    hide: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'hidden'
      }
    },
    delete: async (e: React.BaseSyntheticEvent) => {
      e.stopPropagation()
      deleteShop(shop.id)
    }
  }

  const date = new Date(shop.createdAt)

  return (
    <div
      onClick={goToShop}
      className={styles.box}
      onMouseEnter={deleteHandler.visible}
      onMouseLeave={deleteHandler.hide}
    >
      <div className={styles.naming}>
        <div className={styles.name}>
          <img
            className={styles.img}
            src={`https://api.skidki.promo/img/shops/${shop.img}`}
            alt={shop.name}
            height={'40px'}
          />
          <div>{shop.name}</div>
        </div>
        <div className={styles.posts}>
          {shop.postsCount ? shop.postsCount : '0'}
        </div>
        <div className={styles.date}>{date.toLocaleDateString('ru-RU')}</div>
      </div>
      <div className={styles.delete} ref={deleteRef} onClick={deleteHandler.delete}>
        <AiOutlineDelete />
      </div>
    </div>
  )
}

export default ShopBlock