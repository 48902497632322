import React from 'react'
import { Loader } from '../loader/loader'
import { AnimatePresence, motion } from 'framer-motion'

import styles from './button.module.css'

interface ButtonProps {
  name: string,
  type: 'secondary' | 'primary',
  fullsize?: boolean,
  onClick?: () => void,
  loading?: boolean
  disabled?: boolean
}

export const Button = (props: ButtonProps) => {
  const widthCn = props.fullsize ? styles.fullsize : styles.default
  const cn =
    props.type === 'primary'
      ? `${styles.primary} ${widthCn}`
      : `${styles.secondary} ${widthCn}` 

  const action = () => {
    props.onClick
      ? props.onClick()
      : ''
  }

  return (
    <button onClick={action} className={cn} disabled={props.disabled}>
      <AnimatePresence mode="wait">
        {props.loading ? (
          <motion.div
            key={'loader'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            <Loader color={'white'} />
          </motion.div>
        ) : (
          <motion.div
            key={'text'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            {props.name}
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  )
}