import React, { useState } from 'react'

import SectionsBox from './sections-box/sections-box'
import CreateForm from './create-form/create-form'
import { Search } from '../../ui'

import styles from './sections-page.module.css'

const SectionsPage = () => {
  const [ searchValue, setSearchValue ] = useState('')
  return (
    <main className={styles.box}>
      <div className={styles.title}>Разделы</div>
      <div className={styles.menu}>
        <CreateForm />
        <Search placeholder="Поиск по разделам" handler={setSearchValue} />
      </div>
      <SectionsBox filter={searchValue} />
    </main>
  )
}

export default SectionsPage