import React, { useRef } from 'react'
import { ICategory } from '../../../../../services/types/category.type'
import { useNavigate } from 'react-router-dom'
import { useCategoryDelete } from '../../../../../services/hooks/categoryQuery'

import { AiOutlineDelete } from 'react-icons/ai'

import styles from './categories-item.module.css'

interface CategoriesItemProps {
  category: ICategory
}

const CategoriesItem = ({ category }: CategoriesItemProps) => {
  const navigate = useNavigate()
  const deleteRef = useRef<HTMLDivElement>(null)
  const { mutate: deleteCategory } = useCategoryDelete()

  const goToCategory = () => {
    navigate(`/catalog/categories/${category.id}`)
  }

  const deleteHandler = {
    visible: () => {
      if (deleteRef && deleteRef.current) deleteRef.current.style.visibility = 'visible'
    },
    hide: () => {
      if (deleteRef && deleteRef.current) deleteRef.current.style.visibility = 'hidden'
    },
    delete: async (e: React.BaseSyntheticEvent) => {
      e.stopPropagation()
      deleteCategory(category.id)
    },
  }

  const date = new Date(category.createdAt)
  return (
    <div
      onClick={goToCategory}
      className={styles.box}
      onMouseEnter={deleteHandler.visible}
      onMouseLeave={deleteHandler.hide}
    >
      <div className={styles.naming}>
        <div className={styles.name}>{category.name}</div>
        <div className={styles.subcategories}>{category.subcategories?.length}</div>
        <div className={styles.posts}>0</div>
        <div className={styles.date}>{date.toLocaleDateString('ru-RU')}</div>
      </div>
      <div
        className={styles.delete}
        ref={deleteRef}
        onClick={deleteHandler.delete}
      >
        <AiOutlineDelete />
      </div>
    </div>
  )
}

export default CategoriesItem