import React from 'react'
import styles from './user-avatar.module.css'

interface UserAvatarProps {
  src: string,
  username: string,
}

type color = {
  background: string
  color: string
}

const UserAvatar = (props: UserAvatarProps) => {
  const isAvatarDefault = props.src.includes('default')
  const firstLetter = props.username.toUpperCase().split('')[0]
  const AtoGArr = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'А',
    'Б',
    'В',
    'Г',
    'Д',
    'Е',
    'Ё',
    'З',
    'Ж',
  ]
  const HtoMArr = [
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'И',
    'Й',
    'К',
    'Л',
    'М',
    'Н',
    'О',
    'П',
    'Р',
  ]
  const OtoTArr = [
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'C',
    'Т',
    'У',
    'Ф',
    'Х',
    'Ц',
    'Ч',
    'Ш',
    'Щ',
  ]
  const UtoZArr = ['U', 'V', 'W', 'X', 'Y', 'Z']
  const getBG = (letter: string) => {
    if (AtoGArr.includes(letter)) return 0
    else if (HtoMArr.includes(letter)) return 1
    else if (OtoTArr.includes(letter)) return 2
    else if (UtoZArr.includes(letter)) return 3
    else return 3
  }
  const bgNumber = getBG(firstLetter)

  const colors: color[] = [
    {
      background:
        'linear-gradient(90deg, rgba(255,126,95,1) 0%, rgba(254,180,123,1) 100%)',
      color: '#ffffff',
    },
    {
      background: 'linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)',
      color: '#ffffff',
    },
    {
      background: 'linear-gradient(120deg, #667eea 0%, #764ba2 100%)',
      color: '#ffffff',
    },
    {
      background: 'linear-gradient(120deg, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%)',
      color: '#ffffff',
    },
  ]

  if (isAvatarDefault) return (
    <div className={styles.avatar__box} style={{ ...colors[bgNumber] }}>
      {firstLetter}
    </div>
  )
  return (
    <div className={styles.avatar}>
      <img
        src={`https://api.skidki.promo/img/users/${props.src}`}
        alt={props.username}
        height={'40px'}
        width={'40px'}
      />
    </div>
  )
}

export default UserAvatar