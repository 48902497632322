import axios from "axios";
import { BASE_URL } from "../../../constants/constants";
import { ISection } from "../../../types/section.type";
import { createSectionDto } from "./dto/createSection.dto";

const instanse = axios.create({
  baseURL: `${BASE_URL}/section`
})

export const getOneSection = async (sectionid: string): Promise<ISection> => {
  const res = await instanse.get<ISection>(`${sectionid}`)
  return res.data
}

export const getAllSection = async (): Promise<ISection[]> => {
  const res = await instanse.get<ISection[]>('')
  return res.data
}

export const createSection = async (data: createSectionDto): Promise<ISection> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.post<ISection>('', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const editSection = async (dto: {data: createSectionDto, id: string | number}): Promise<ISection> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.patch<ISection>(`${dto.id}`, dto.data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const deleteSection = async (sectionid: string | number): Promise<{success: boolean, message: string}> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.delete<{success: boolean, message: string}>(`${sectionid}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}