import React, { useRef } from 'react'
import { ISubcategory } from '../../../../../services/types/subcategory.type'

import { AiOutlineDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import styles from './subcategories-item.module.css'
import { useSubcategoryDelete } from '../../../../../services/hooks/subcategoryQuery'

interface SubcategoriesItemProps {
  subcategory: ISubcategory
}

const SubcategoriesItem = ({ subcategory }: SubcategoriesItemProps) => {
  const navigate = useNavigate()
  const { mutate } = useSubcategoryDelete()
  const deleteRef = useRef<HTMLDivElement>(null)

  const goToSubcategories = () => {
    navigate(`/catalog/subcategories/${subcategory.id}`)
  }

  const deleteHandler = {
    visible: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'visible'
      }
    },
    hide: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'hidden'
      }
    },
    delete: async (e: React.BaseSyntheticEvent) => {
      e.stopPropagation()
      mutate(subcategory.id)
    },
  }

  const date = new Date(subcategory.createdAt)
  return (
    <div
      onClick={goToSubcategories}
      className={styles.box}
      onMouseEnter={deleteHandler.visible}
      onMouseLeave={deleteHandler.hide}
    >
      <div className={styles.naming}>
        <div className={styles.name}>{subcategory.name}</div>
        <div className={styles.posts}>0</div>
        <div className={styles.date}>{date.toLocaleDateString('ru-RU')}</div>
      </div>
      <div
        className={styles.delete}
        ref={deleteRef}
        onClick={deleteHandler.delete}
      >
        <AiOutlineDelete />
      </div>
    </div>
  )
}

export default SubcategoriesItem