import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Loader } from '../../ui'
import { useOneSubcategoryQuery } from '../../../services/hooks/subcategoryQuery'
import { useDispatch, useSelector } from '../../../services/hooks/reduxHooks'
import { openLeftModal } from '../../../services/redux/slices/modal'

import styles from './subcategories-detail-page.module.css'
import LeftModal from '../../ui/left-modal/left-modal'
import EditForm from './edit-form/edit-form'

const SubcategoriesDetailPage = () => {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(store => store.modal.isLeftModalOpen)
  const { id } = useParams()
  const { isLoading, isSuccess, data } = useOneSubcategoryQuery(id!)

  if (isLoading) return (
    <div className={styles.loader}>
      <Loader />
    </div>
  )

  if (isSuccess && data) return (
    <div className={styles.box}>
      {isModalOpen && <LeftModal title='Редактировать'><EditForm name={data.name} link={data.link} id={id!} /></LeftModal>}
      <div className={styles.breadcrumbs}>
        <Link to={`/catalog/subcategories`} className={styles.link}>
          Подкатегории
        </Link>
        <div>/</div>
        <div>{data.name}</div>
      </div>
      <div className={styles.title__box}>
        <div className={styles.title}>{data.name}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.info__item}>
          <p className={styles.info__title}>Категория:</p>
          <Link to={`/catalog/categories/`} className={styles.link}>
            {data.category.name}
          </Link>
        </div>
        <div className={styles.info__item}>
          <p className={styles.info__title}>Посты:</p>
          <p className={styles.link}>0</p>
        </div>
        <div
          className={styles.edit}
          onClick={() => {
            dispatch(openLeftModal())
          }}
        >
          Редактировать
        </div>
      </div>
    </div>
  )

  return <Navigate to={'catalog/subcategories'} />
}

export default SubcategoriesDetailPage