import { configureStore } from "@reduxjs/toolkit"
import userReducer from './slices/user'
import statsReducer from './slices/stats'
import modalReducer from './slices/modal'

const store = configureStore({
  reducer: {
    user: userReducer,
    stats: statsReducer,
    modal: modalReducer
  },
  devTools: process.env.NODE_ENV === 'development'
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch