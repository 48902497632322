import React, { useState } from 'react'

import SubategoriesBox from './subcategories-box/subcategories-box'
import CreateForm from './create-form/create-form'
import { Search } from '../../ui'

import styles from './subcategories-page.module.css'

const SubcategoriesPage = () => {
  const [searchValue, setSearchValue] = useState('')
  return (
    <main className={styles.box}>
      <div className={styles.title}>Подкатегории</div>
      <div className={styles.menu}>
        <CreateForm />
        <Search placeholder="Поиск по подкатегориям" handler={setSearchValue} />
      </div>
      <SubategoriesBox filter={searchValue} />
    </main>
  )
}

export default SubcategoriesPage