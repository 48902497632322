import axios from "axios";
import { BASE_URL } from "../../../constants/constants";
import { createSubcategoryDto } from "./dto/createSubcategory.dto";
import { ISubcategory } from "../../../types/subcategory.type";

const instanse = axios.create({
  baseURL: `${BASE_URL}/subcategory`
})

export const getOneSubcategory = async (id: string | number): Promise<ISubcategory> => {
  const res = await instanse.get<ISubcategory>(`${id}`)
  return res.data
}

export const getAllSubcategories = async (): Promise<ISubcategory[]> => {
  const res = await instanse.get<ISubcategory[]>('')
  return res.data
}

export const editSubcategory = async (dto: { data: createSubcategoryDto, id: string | number }): Promise<ISubcategory> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.patch<ISubcategory>(`${dto.id}`, dto.data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const createSubcategory = async (data: createSubcategoryDto): Promise<ISubcategory> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.post<ISubcategory>('', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export const deleteSubcategory = async (subcategoryid: string | number): Promise<{success: boolean, message: string}> => {
  const token = localStorage.getItem('accessToken')
  const res = await instanse.delete<{success: boolean, message: string}>(`${subcategoryid}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}