import React, { BaseSyntheticEvent, useRef } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import { IPost } from '../../../../../services/types/post'

import styles from './post-block.module.css'
import { postApi } from '../../../../../services/api/post.api'

interface IProps {
  post: IPost
}

const PostBlock = ({ post }: IProps) => {
  const navigate = useNavigate()
  const deleteRef = useRef<HTMLDivElement>(null)

  const deletePostHandler = async () => {
    await postApi.delete(post.id)
  }

  const goToPostDetail = () => {
    navigate(`/posts/${post.id}/info`)
  }

  const deleteHandler = {
    visible: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'visible'
      }
    },
    hide: () => {
      if (deleteRef && deleteRef.current) {
        deleteRef.current.style.visibility = 'hidden'
      }
    },
    delete: async (e: BaseSyntheticEvent) => {
      e.stopPropagation()
      await deletePostHandler()
    }
  }

  const date = new Date(post.createdAt)
  return (
    <div
      onClick={goToPostDetail}
      className={styles.box}
      onMouseEnter={deleteHandler.visible}
      onMouseLeave={deleteHandler.hide}
    >
      <div className={styles.naming}>
        <div className={styles.name}>{post.title}</div>
        <div className={styles.type}>{post.type}</div>
        <div className={styles.user}>{post.user?.username}</div>
        <div className={styles.shop}>
          {post.shop ? post.shop.name : 'Не указано'}
        </div>
        <div className={styles.subcategory}>
          {post.subcategory
            ? post.subcategory.name
            : post.category
            ? post.category.name
            : post.section
            ? post.section.name
            : 'Не указано'}
        </div>
        <div className={styles.likes}>{post.likesCount}</div>
        <div className={styles.date}>
          <p>{date.toLocaleDateString('ru-RU')}</p>
        </div>
      </div>
      <div
        className={styles.delete}
        ref={deleteRef}
        onClick={deleteHandler.delete}
      >
        <AiOutlineDelete />
      </div>
    </div>
  )
}

export default PostBlock