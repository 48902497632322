import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createSection, deleteSection, editSection, getAllSection, getOneSection } from "../api/catalog/section"
import store from "../redux"
import { closeLeftModal } from "../redux/slices/modal"

const useOneSectionQuery = (sectionid: string) => {
  return useQuery({
    queryFn: () => getOneSection(sectionid),
    queryKey: ['section'],
    retry: false
  })
}

const useSectionQuery = () => {
  return useQuery({
    queryFn: () => getAllSection(),
    queryKey: ['sections', 'all']
  })
}

const useSectionCreate = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: createSection,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['sections']
      })
    }
  })
}

const useSectionEdit = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: editSection,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['section']
      })
      store.dispatch(closeLeftModal())
    }
  })
}

const useSectionDelete = () => {
  const client = useQueryClient()
  
  return useMutation({
    mutationFn: deleteSection,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ['sections']
      })
    }
  })
}

export { useOneSectionQuery, useSectionQuery, useSectionCreate, useSectionDelete, useSectionEdit }