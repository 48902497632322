import axios from 'axios'
import { AppDispatch } from '../redux'
import { addUser, authCheckFailed, authCheckSuccess, deleteUser, userLoginError, userLoginRequest } from '../redux/slices/user'
import { getMeRes, LoginDto, LoginRes } from '../types/user'
import { BASE_URL } from '../constants/constants'

const instanse = axios.create({
  baseURL: `${BASE_URL}/auth`
})

export const userApi = {
  login(data: LoginDto, dispatch: AppDispatch) {
    dispatch(userLoginRequest())
    instanse.post<LoginRes>('/login', data)
      .then(res => {
        if (res.data.user.role !== 'admin') throw new Error()
        else {
          localStorage.setItem('accessToken', res.data.accessToken)
          dispatch(addUser(res.data.user))
        }
      })
      .catch(err => dispatch(userLoginError()))
  },
  logout(dispatch: AppDispatch) {
    dispatch(deleteUser())
    localStorage.clear()
  },
  checkAuth(dispatch: AppDispatch) {
    const token = localStorage.getItem('accessToken')
    if (!token) dispatch(authCheckFailed())
    instanse.get<getMeRes>('/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      dispatch(authCheckSuccess(res.data.user))
    })
    .catch(err => {
      dispatch(authCheckFailed())
      localStorage.clear()
    })
  }
}