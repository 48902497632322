import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAllUsers } from '../../../services/api/users'

import UserBlock from './user-block/user-block'
import { Loader, Search } from '../../../components/ui'

import styles from './users-page.module.css'

const UsersPage = () => {
  const { data, isLoading, isSuccess } = useQuery({
    queryFn: () => getAllUsers(),
    queryKey: ['users']
  })
  
  if (isLoading)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    )

  if (isSuccess && data)
    return (
      <main className={styles.box}>
        <div className={styles.title}>Пользователи</div>
        {/* <Search placeholder="Поиск по пользователям" /> */}
        <div className={styles.users__box}>
          <div className={styles.users__title}>
            <div className={styles.username}>Имя</div>
            <div className={styles.role}>Роль</div>
            <div className={styles.posts}>Посты</div>
            <div className={styles.comments}>Комментарии</div>
            <div className={styles.reg}>Регистрация</div>
            <div className={styles.visit}>Посл. визит</div>
            <div className={styles.status}>Статус</div>
          </div>
          {data.users.map((user) => (
            <UserBlock user={user} key={user.id} />
          ))}
        </div>
      </main>
    )

  return <div>Пользователей нет</div>
}

export default UsersPage