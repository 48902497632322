import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import styles from './user-charts.module.css'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      intersect: false,
    },
  },
  scales: {
    y: {
      display: false,
      grid: {
        display: false,
      },
      min: 0,
    },
    x: {
      grid: {
        display: true,
        color: '#f2f2f2',
      },
      border: {
        dash: [6, 4],
      },
    },
  },
}

const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
export const data = {
  labels,

  datasets: [
    {
      label: 'Регистрации',
      fill: 'start',
      data: [18, 24, 20, 23, 24, 28, 19, 19, 19, 20],
      borderColor: '#316cec',
      backgroundColor: (contex: ScriptableContext<'line'>) => {
        const ctx = contex.chart.ctx
        const gradient = ctx.createLinearGradient(0, 0, 0, 250)
        gradient.addColorStop(0, 'rgba(24, 147, 255, 0.8)')
        gradient.addColorStop(1, 'rgba(24, 147, 255, 0.04)')
        return gradient
      },
      pointStyle: false,
    },
  ],
}

interface UserChartsProps {
  count: number
  new: number
}

const UserCharts = (props: UserChartsProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.heading}>
        <div className={styles.title}>Пользователи</div>
        <div className={styles.stat}>
          <div className={styles.count}>{props.count}</div>
          <div className={styles.new}>+{props.new}</div>
        </div>
      </div>
      <Line
        options={options}
        // @ts-ignore
        data={data}
        height={120}
      />
    </div>
  )
}

export default UserCharts