import React from 'react'
import Menu from '../menu/menu'
import styles from './layout.module.css'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  return (
    <div className={styles.app}>
      <Menu />
      {props.children}
    </div>
  )
}

export default Layout